import {useDispatch} from "react-redux";
import {ChangeEvent, useCallback} from "react";
import {Token} from "../../../data/machines/MachinesState";
import {saveTokenChanges} from "../../../data/machines/actions";

export const useMachineTokenChange = (token: Token) => {
    const dispatch = useDispatch();
    return useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        token.token = event.target.value;
        dispatch(saveTokenChanges(token));
    }, [dispatch, token]);
};