import {Machine} from "../../../data/machines/MachinesState";
import {File} from "../../../data/files/State";

export enum PriceTypes {
    current,
    min,
}

export const CONFIG_DIALOG_ON_PRICE_INPUT_ACTION_NAME = "CONFIG_DIALOG_ON_PRICE_INPUT";
export type ConfigDialogOnPriceInput_Types = {
    type: typeof CONFIG_DIALOG_ON_PRICE_INPUT_ACTION_NAME;
    value: string,
    priceType: PriceTypes,
}


export const CONFIG_DIALOG_ON_PRICE_SET_ACTION_NAME = "CONFIG_DIALOG_ON_PRICE_SET";
export type ConfigDialogOnPriceSet_Types = {
    type: typeof CONFIG_DIALOG_ON_PRICE_SET_ACTION_NAME;
    value: number;
    priceType: PriceTypes,
}

export const CONFIG_DIALOG_ON_CLOSE_ACTION_NAME = "CONFIG_DIALOG_ON_CLOSE";
export type ConfigDialogOnClose_Types = {
    type: typeof CONFIG_DIALOG_ON_CLOSE_ACTION_NAME;
    save: boolean,
}

export const CONFIG_DIALOG_OPEN_ACTION_NAME = "CONFIG_DIALOG_OPEN";
export type ConfigDialogOpen_Types = {
    type: typeof CONFIG_DIALOG_OPEN_ACTION_NAME;
    machine: Machine,
}

export const CONFIG_DIALOG_DATA_ACTION_NAME = "CONFIG_DIALOG_DATA";
export type CONFIG_DIALOG_DATA_Types = {
    type: typeof CONFIG_DIALOG_DATA_ACTION_NAME;
    data: GetMachineConfigDTO;
}

export const CONFIG_DIALOG_EDIT_ALIAS_ACTION_NAME = "CONFIG_DIALOG_EDIT_ALIAS";
export type ConfigDialogEditAlias_Types = {
    type: typeof CONFIG_DIALOG_EDIT_ALIAS_ACTION_NAME;
    alias: string,
}

export const CONFIG_DIALOG_EDIT_VIDEO_ACTION_NAME = "CONFIG_DIALOG_EDIT_VIDEO";
export type ConfigDialogEditVideo_Types = {
    type: typeof CONFIG_DIALOG_EDIT_VIDEO_ACTION_NAME;
    video: File | null,
}

export const CONFIG_DIALOG_ON_OUT_OF_SERVICE_ACTION_NAME = "CONFIG_DIALOG_ON_OUT_OF_SERVICE";
export type ConfigDialogOnOutOfService_Types = {
    type: typeof CONFIG_DIALOG_ON_OUT_OF_SERVICE_ACTION_NAME;
    outOfService: boolean,
}


export type ConfigDialogActions =
    ConfigDialogOnPriceInput_Types
    | ConfigDialogOnPriceSet_Types
    | ConfigDialogOnClose_Types
    | ConfigDialogOpen_Types
    | CONFIG_DIALOG_DATA_Types
    | ConfigDialogEditAlias_Types
    | ConfigDialogEditVideo_Types
    | ConfigDialogOnOutOfService_Types
    ;


export const GET_MACHINE_CONFIG = "GET_MACHINE_CONFIG";

export interface GetMachineConfigDTO {
    name: typeof GET_MACHINE_CONFIG;
    data: {
        machine: string,
        pricing: {
            min: number,
            current: number,
        },
        video: string,
        others: object,
    }
}

export interface SetMachineConfigDTO {
    "min-pricing": number,
    "current-pricing": number,
    "alias"?: string,
    "video"?: string, // uuid of file
}

export interface SetMachineSettingsDTO {
    "out-of-service": boolean,
}