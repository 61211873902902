import {OrganisationInformation, OrganisationInformationDTO} from "../actions/create";

export const convertOrganisationInfoToDTO = (information: OrganisationInformation): OrganisationInformationDTO => {
    return {
        city: information.city,
        country_code: information.countryCode,
        create_location: information.createLocation,
        name: information.name,
        others: information.others,
        postal_code: information.postalCode,
        street: information.street,
        street_number: information.streetNumber
    };
};
