import {NotificationsState} from "../NotificationsState";
import {useSelector} from "react-redux";
import {StoreState} from "../../StoreState";

const useNotificationState = (): NotificationsState => {
    return useSelector<StoreState, NotificationsState>(({notifications}) => {
        return notifications;
    })
};

export const useNotification = () => {
    return {
        open: useNotificationState().showError,
        message: useNotificationState().error?.description || "unknown"
    }
};