import {MachinesState} from "./MachinesState";
import {
    MACHINE_EDIT_TOKEN_ACTION, MACHINE_EDITABLE_TOKEN_ACTION,
    MACHINE_FETCHED_ACTION,
    MACHINE_FETCHED_TOKEN_ACTION, MachineActions,
    MachineEditableTokenAction, MachineEditTokenAction,
    MachineFetchedAction, MachineFetchedTokenAction
} from "./types";

const initState: MachinesState = {
    machines: [],
    tokens: [],
}

function machineFetched(oldState: MachinesState, action: MachineFetchedAction) {
    return Object.assign({}, oldState, {
        machines: action.machines,
    });
}

function tokenFetched(oldState: MachinesState, action: MachineFetchedTokenAction) {
    return Object.assign({}, oldState, {
        tokens: action.tokens.map((token, index) => {
            if (!oldState.tokens[index]) {
                return token;
            }

            if (token.machine === oldState.tokens[index].machine) {
                return Object.assign({}, token, {
                    token: token.expired ? "" : oldState.tokens[index].token,
                    saved: token.expired ? false : oldState.tokens[index].saved,
                });
            }
            return token;
        }),
    });
}

function editMachineToken(oldState: MachinesState, action: MachineEditTokenAction) {
    return Object.assign({}, oldState, {
        tokens: oldState.tokens.map((token) => {
            if (token.machine !== action.token.machine) return token;
            return Object.assign({}, token, {
                token: action.token.token,
                saved: false,
            });
        }),
    });
}

function editableToken(oldState: MachinesState, action: MachineEditableTokenAction) {
    return Object.assign({}, oldState, {
        tokens: oldState.tokens.map((token) => {
            if (token.machine !== action.token.machine) return token;
            return {
                ...token,
                editable: action.token.editable,
                saved: action.token.saved,
            };
        }),
    });
}

function machineReducer(state: MachinesState = initState, action: MachineActions) {
    switch (action.type) {
        case MACHINE_FETCHED_ACTION:
            return machineFetched(state, action);
        case MACHINE_FETCHED_TOKEN_ACTION:
            return tokenFetched(state, action);
        case MACHINE_EDIT_TOKEN_ACTION:
            return editMachineToken(state, action);
        case MACHINE_EDITABLE_TOKEN_ACTION:
            return editableToken(state, action);
        default:
            return state;
    }
}

export {machineReducer}