type active = { id: "active", text: "Active" };
type outOfService = { id: "out-of-service", text: "Out of service" };
type expired = { id: "expired", text: "Expired" };

export const ACTIVE: active = {id: "active", text: "Active"};
export const OUT_OF_SERVICE: outOfService = {id: "out-of-service", text: "Out of service"};
export const EXPIRED: expired = {id: "expired", text: "Expired"};

export type State = active | outOfService | expired;
export const STATES = [ACTIVE, OUT_OF_SERVICE, EXPIRED];

export interface TableFilterState {
    states: State[],
    statePopupOpen: boolean,
    searchField: string,
}