import React from 'react';
import {
    Tabs,
    Tab,
    IconButton,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import routes from '../../../routes.js';
import {getAuthPermissions} from '../../../auth/managers/sessionManager';
import {ExitToApp as LogoutIcon} from '@material-ui/icons';
import {useLogout} from "../../../auth/hooks/Login";

const DesktopHeader = props => {
    const authPermissions = getAuthPermissions();
    let authPermissionsParsed = authPermissions.split('|');
    let onLogout = useLogout("Pressed logout button");

    return (
        <>
            <Tabs value={props.currentPage} key="Desktopmenu">
                {routes.map((route, index) => {
                    let allowed = route.requiredPermissions.reduce((acc, requiredPermission) => {
                        return authPermissionsParsed.includes(requiredPermission) && acc;
                    }, true);

                    if (allowed) {
                        return <Tab label={route.header} component={Link} to={process.env.PUBLIC_URL + route.path}
                                    value={process.env.PUBLIC_URL + route.path} key={route.path}/>
                    } else {
                        return null;
                    }
                })}
            </Tabs>
            <div style={{flexGrow: 1}} key="Flexgrow"/>
            <IconButton onClick={onLogout} key="Logoutbutton">
                <LogoutIcon/>
            </IconButton>
        </>)
}

export default DesktopHeader;
