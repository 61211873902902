import React, {ChangeEvent, FC, FocusEvent} from "react";
import {TextField, Typography} from "@material-ui/core";

export interface FullWidthTextFieldViewStates {
    placeholder: string,
    name: string,
    value: string,
    error: boolean,
    errorText: string,
    enabled: boolean,
}

export interface FullWidthTextFieldViewDispatches {
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
    onFinished: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
}

export type FullWidthTextFieldViewProps = FullWidthTextFieldViewStates & FullWidthTextFieldViewDispatches;

export const FullWidthTextFieldView: FC<FullWidthTextFieldViewProps> = ({
                                                                            placeholder,
                                                                            name,
                                                                            value,
                                                                            error,
                                                                            errorText,
                                                                            enabled,
                                                                            onChange,
                                                                            onFinished
                                                                        }) => {
    return (
        <TextField
            fullWidth
            name={name}
            label={placeholder}
            onChange={onChange}
            margin="dense"
            value={value}
            onBlur={onFinished}
            error={error}
            disabled={!enabled}
            helperText={<Typography
                variant="caption"
                style={{whiteSpace: 'pre-line'}}
            >{errorText}</Typography>}
        />
    );
};