import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {Paper, Grid, colors, Box} from '@material-ui/core';
import { Line } from 'react-chartjs-2';
// import {getJson} from '../../managers/apiManager';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 12,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "calc(50vh - 8px - 24px - 8px)", // TODO: Dirty solution should find better one
  },
});

const dataset = (title, data, color) => ({
  label: title,
  fill: false,
  lineTension: 0.1,
  backgroundColor: fade(color, 0.4),
  borderColor: color,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: color,
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: color,
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
  data,
});

const baseConfig = (data, labels) => ({
  labels,
  datasets: [
    dataset("Cost", data.cost, colors.deepPurple[500]),
    dataset("Coins", data.coins, colors.lightGreen[600]),
    dataset("Transactions", data.transactions, colors.amber[400]),
  ]
});

const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

function hoursAgoLabel(hoursAgo) {
  let time = new Date(Date.now() - 1000 * 60 * 60 * hoursAgo);
  return String(time.getHours()).padStart(2, "0") + ":00";
}

function daysAgoLabel(daysAgo, fullDate = false) {
  let time = new Date(Date.now() - 1000 * 60 * 60 * 24 * daysAgo);
  if(fullDate) {
    return String(time.getDate()).padStart(2, "0") + "-" + String(time.getMonth() + 1).padStart(2, "0") + "-" + time.getFullYear();
  } else {
    return days[time.getDay()];
  }
}

const pastDayConfig = data => baseConfig(data, Array(24).fill(7).map((data, index) => [-1 * index + "h", hoursAgoLabel(index)]).reverse());
const pastWeekConfig = data => baseConfig(data, Array(7).fill(7).map((data, index) => [-1 * index + "d", daysAgoLabel(index)]).reverse());
const pastMonthConfig = data => baseConfig(data, Array(30).fill(7).map((data, index) => [-1 * index + "d", daysAgoLabel(index, true)]).reverse());

const options = title => ({
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      ticks: {
        min: 0,
      }
    }],
  },
  title: {
    display: true,
    text: title,
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        let label = data.datasets[tooltipItem.datasetIndex].label + ": " || '';
        label += tooltipItem.datasetIndex === 0 ? '€' : '';
        label += String(tooltipItem.yLabel).replace(".", ",");
        return label;
      },
    },
  },
});

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pastDayData: pastDayConfig({cost: Array(24).fill(0), coins: Array(24).fill(0), transactions: Array(24).fill(0)}),
      pastWeekData: pastWeekConfig({cost: Array(7).fill(0), coins: Array(7).fill(0), transactions: Array(7).fill(0)}),
      pastMonthData: pastMonthConfig({cost: Array(30).fill(0), coins: Array(30).fill(0), transactions: Array(30).fill(0)}),
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.fetchStatistics(), 1000 * 60);
    this.fetchStatistics();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState) {
      if(prevProps.locationSelect !== this.props.locationSelect) {
        this.fetchStatistics(this.props.locationSelect);
      }
  }

  fetchStatistics(location = this.props.locationSelect) {
    if(!location) return;
    // getJson(`/${location}/overview`)
    //   .then(response => {
    //     if(response.success) {
    //       this.setState({
    //         pastDayData: pastDayConfig(response.details.hours),
    //         pastWeekData: pastWeekConfig(response.details.days),
    //         pastMonthData: pastMonthConfig(response.details.month),
    //       });
    //     }
    //   });
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.root} m={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Line data={this.state.pastDayData} options={options("Past 24 hours")} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Line data={this.state.pastWeekData} options={options("Past 7 days")} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Line data={this.state.pastMonthData} options={options("Past 30 days")} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(Statistics);
