import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import {merge, pipe} from "rxjs";
import {
    MOVE_MACHINES_ACTION,
    MoveMachineAction, OPEN_LOCATION_CHOOSER, OpenLocationChooser,
    ORGANISATION_RECEIVE_ACTION,
    ORGANISATION_SELECT_ACTION,
    OrganisationAction,
    OrganisationReceiveAction,
    OrganisationSelectAction,
    retreiveOrganisationList,
    setCurrentOrganisations
} from "../actions";
import {concatMap, filter, map,} from "rxjs/operators";
import {ApiRequestAction, sendJsonApiRequest} from "../../../api/actions";
import config from "../../../config";
import {StoreState} from "../../../StoreState";
import {callBatchActions} from "../../../batchCall/actions";
import {
    CREATE_ORGANISATION__RESPONSE_ACTION,
    CREATE_ORGANISATION_ACTION,
    CreateOrganisationAction, CreateOrganisationResponseAction,
} from "../actions/create";
import {convertOrganisationInfoToDTO} from "../hooks";
import {
    LOCATION_RESULT_ACTION,
    LocationResultAction,
    ORGANISATION_RESULT_ACTION,
    OrganisationResultAction
} from "../actions/reponses";


const handleOrganisationReceiveAction = (action$: ActionsObservable<OrganisationAction>) => action$.pipe(
    ofType(ORGANISATION_RECEIVE_ACTION),
    pipe(
        map((action) => action as OrganisationReceiveAction),
        concatMap((async () => {
            return sendJsonApiRequest({
                name: "GET_ORGANISATIONS",
                url: config.apiUrl + "/organisations",
                method: "GET",
                headers: [],
                requireAuth: true,
                body: "",
            });
        }))
    )
);

const handleUpdateOrganisation = (action$: ActionsObservable<OrganisationAction>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(ORGANISATION_RESULT_ACTION),
    pipe(
        map((action) => action as OrganisationResultAction),
        concatMap((async () => {
            let selectedOrganisation = null;
            const state = state$.value.machineAdministration;
            state.currentOrganisation === null || state.organisations.every((organisation) => {
                if (state.currentOrganisation?.uuid === organisation.uuid) {
                    selectedOrganisation = organisation;
                    return false;
                }

                return true;
            });

            return setCurrentOrganisations(
                selectedOrganisation || state.organisations[0] || null,
                selectedOrganisation === null);
        }))
    )
);


const handleOrganisationSelectChange = (action$: ActionsObservable<OrganisationAction>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(ORGANISATION_SELECT_ACTION),
    map((action) => action as OrganisationSelectAction),
    filter((action) => action.organisation !== null),
    filter((action) => action.hasChanged),
    pipe(
        concatMap((async (action) => {
            return sendJsonApiRequest({
                name: "GET_LOCATIONS",
                url: config.apiUrl + "/" + action.organisation?.uuid + "/locations",
                method: "GET",
                headers: [],
                requireAuth: true,
                body: "",
            });
        }))
    )
);

const handleLocationUpdate = (action$: ActionsObservable<OrganisationAction>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(LOCATION_RESULT_ACTION),
    map((action) => action as LocationResultAction),
    pipe(
        concatMap((async (action) => {
            const actionsOut: ApiRequestAction[] = [];
            action.locations.forEach((location) => {
                actionsOut.push(
                    sendJsonApiRequest({
                        name: "GET_MACHINES",
                        url: config.apiUrl + "/" +
                            state$.value.machineAdministration.currentOrganisation?.uuid + "/" +
                            location.uuid + "/machines",
                        method: "GET",
                        headers: [],
                        requireAuth: true,
                        body: "",
                        responseVariables: [location],
                    })
                );
            });

            return callBatchActions(actionsOut);
        }))
    )
);

const handleNewOrganisationRequest = (action$: ActionsObservable<OrganisationAction>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(CREATE_ORGANISATION_ACTION),
    map((action) => action as CreateOrganisationAction),
    pipe(
        concatMap((async (action) => {
            return sendJsonApiRequest({
                name: "CREATE_ORGANISATION",
                url: config.apiUrl + "/maintenance/new-organisation",
                method: "POST",
                headers: [],
                requireAuth: true,
                body: JSON.stringify(convertOrganisationInfoToDTO(action.information)),
            });
        }))
    )
);

const handleNewOrganisationResponse = (action$: ActionsObservable<OrganisationAction>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(CREATE_ORGANISATION__RESPONSE_ACTION),
    map((action) => action as CreateOrganisationResponseAction),
    pipe(
        concatMap((async (action) => {
            return retreiveOrganisationList();
        }))
    )
);

const handleOpenLocationChooser = (action$: ActionsObservable<OrganisationAction>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(OPEN_LOCATION_CHOOSER),
    map((action) => action as OpenLocationChooser),
    pipe(
        concatMap((async (action) => {
            return sendJsonApiRequest({
                name: "GET_LOCATIONS_DIALOG",
                url: config.apiUrl + "/" + action.organisation.uuid + "/locations",
                method: "GET",
                headers: [],
                requireAuth: true,
                body: "",
            });
        }))
    )
);

const handleMoveMachineRequest = (action$: ActionsObservable<OrganisationAction>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(MOVE_MACHINES_ACTION),
    map((action) => action as MoveMachineAction),
    pipe(
        concatMap((async (action) => {
            return sendJsonApiRequest({
                name: "MOVE_MACHINE",
                url: config.apiUrl + "/machine/" + action.machine.uuid + "/move-to",
                method: "POST",
                headers: [],
                requireAuth: true,
                body: JSON.stringify({location: action.destination}),
                responseVariables: [action.machine, action.destination]
            });
        }))
    )
);

export const organisationEpic = (action$: ActionsObservable<OrganisationAction>, state$: StateObservable<StoreState>) => merge(
    handleOrganisationReceiveAction(action$),
    handleUpdateOrganisation(action$, state$),
    handleOrganisationSelectChange(action$, state$),
    handleLocationUpdate(action$, state$),
    handleNewOrganisationRequest(action$, state$),
    handleNewOrganisationResponse(action$, state$),
    handleMoveMachineRequest(action$, state$),
    handleOpenLocationChooser(action$, state$),
);