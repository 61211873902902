import {FC, memo, useEffect} from "react";
import React from "react";
import {Box, Paper, Fab, TableContainer} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {AutoHideTableCell, StyledTableCell} from "../Machines/MachineView";
import {AddDialog} from "./AddDialog/Controller";
import {FilesTableData} from "./Components/FilesTableData";
import {File} from "../../data/files/State"

export interface FilesPageViewStates {
    files: File[]
}

export interface FilesPageViewDispatches {
    onAdd: () => void,
    pull: () => void,
    onFileRemove: (file: File) => void,
    onDownload: (file: File) => void,
}

export type FilesPageViewProps =
    FilesPageViewStates
    & FilesPageViewDispatches
    ;

export const FilesPageView: FC<FilesPageViewProps> = memo(({
                                                               files,
                                                               onAdd,
                                                               pull,
                                                               onFileRemove,
                                                               onDownload
                                                           }) => {
        useEffect(() => {
            pull();

            const timer = setInterval(pull, 10 * 1000);
            return () => {
                clearInterval(timer);
            }
        }, [pull]);

        return (
            <Box m={1}>
                <Paper>
                    <TableContainer component={"div"}>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>File</StyledTableCell>
                                    <AutoHideTableCell>Type</AutoHideTableCell>
                                    <StyledTableCell align="right">Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <FilesTableData files={files} onRemove={onFileRemove} onDownload={onDownload}/>
                        </Table>
                    </TableContainer>
                    <AddDialog/>
                    <Fab color="primary" aria-label="add" onClick={onAdd}
                         style={{
                             position: "fixed",
                             bottom: "10px",
                             right: "10px",
                         }}>
                        <AddIcon/>
                    </Fab>
                </Paper>
            </Box>
        );
    }
)