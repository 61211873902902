import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import {merge, pipe} from "rxjs";
import {StoreState} from "../../../StoreState";
import {
    ADD_DIALOG_CLOSE_ACTION_NAME,
    AddDialogActionTypes,
    AddDialogClose_Types,
    INIT_UPLOAD,
    UPLOAD_CHUNK,
    UPLOAD_CHUNK_ACTION_NAME,
    UploadChunk_Types
} from "./Types";
import {concatMap, filter, map} from "rxjs/operators";
import * as Yup from 'yup';
import {Invalidate} from "./Actions";
import {sendJsonApiRequest} from "../../../api/actions";
import config from "../../../config";
import {FILE_TYPE_NONE, FILE_TYPE_UNSUPPORTED} from "./State";
import {CHUNK_SIZE} from "./Reducer";
import {onError} from "../../../notifications/actions";
import {UnknownErrorNum} from "../../../notifications/NotificationsState";
import {isString} from "formik";
import {RemoveFile} from "../../../data/files/Actions";

const nameRequirement = Yup.string()
    .required()
    .min(3)
    .max(32)
    .matches(/^[a-zA-Z]\w+$/);

const ToBase64 = async (blob: Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64 = reader.result;
            if (!isString(base64)) {
                reject();
                return;
            }

            const result = base64.substr(base64.indexOf(',') + 1);
            resolve(result);
        }
        reader.onerror = () => reject();
        reader.readAsDataURL(blob);

    })
}

// function stretchEmissions(source, spacingDelayMs) {
//     return source
//         .timestamp()
//         .scan((acc, curr) => {
//             // calculate delay needed to offset next emission
//             let delay = 0;
//             if (acc !== null) {
//                 const timeDelta = curr.timestamp - acc.timestamp;
//                 delay = timeDelta > spacingDelayMs ? 0 : (spacingDelayMs - timeDelta);
//             }
//
//             return {
//                 timestamp: curr.timestamp,
//                 delay: delay,
//                 value: curr.value
//             };
//         }, null)
//         .mergeMap(i => Rx.Observable.of(i.value).delay(i.delay), undefined, 1);
// }
//
// function stretch(timeSpaceMs: number) {
//     interface ScanObj<T> {
//         delay: number,
//         obj: T,
//         timestamp: number,
//     }
//
//     return function <T>(source: Observable<T>) {
//         return source.pipe(
//             timestamp(),
//             scan((prev: ScanObj<T> | null, current: Timestamp<T>) => {
//                 const noDelay: ScanObj<T> = {
//                     delay: 0,
//                     timestamp: current.timestamp,
//                     obj: current.value,
//                 }
//                 if (prev == null) {
//                     console.log("No delay, first");
//                     return noDelay;
//                 }
//
//                 const diff = current.timestamp - prev.timestamp;
//                 if (diff > timeSpaceMs) {
//                     console.log("No delay, big time");
//                     return noDelay;
//                 }
//                 console.log("Delaying...");
//
//                 return {
//                     delay: timeSpaceMs - diff,
//                     obj: current.value,
//                     timestamp: current.timestamp,
//                 };
//             }, null),
//             mergeMap((scanObj) => {
//                 if (!scanObj) return of(null);
//
//                 return of(scanObj.obj)
//                     .pipe(
//                         delay(scanObj.delay)
//                     )
//             })
//         );
//     }
// }

const handlerSaveAction = (action$: ActionsObservable<AddDialogActionTypes>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(ADD_DIALOG_CLOSE_ACTION_NAME),
    map((action) => action as AddDialogClose_Types),
    filter((action) => action.save),
    map(() => {
        const fileType = state$.value.filesAdd.fileType;
        const fileInvalid = fileType === FILE_TYPE_NONE || fileType === FILE_TYPE_UNSUPPORTED;
        const nameInvalid = !nameRequirement.isValidSync(state$.value.filesAdd.name);

        if (nameInvalid || fileInvalid) {
            return Invalidate(nameInvalid, fileInvalid);
        }

        return sendJsonApiRequest({
            name: INIT_UPLOAD,
            url: config.apiUrl + "/v2/file",
            method: "POST",
            headers: [],
            requireAuth: true,
            body: JSON.stringify({
                "data": {
                    "name": state$.value.filesAdd.name,
                    "type": state$.value.filesAdd.fileType,
                    "filename": state$.value.filesAdd.file?.name,
                }
            })
        })
    })
);

const handlerCancelAction = (action$: ActionsObservable<AddDialogActionTypes>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(ADD_DIALOG_CLOSE_ACTION_NAME),
    map((action) => action as AddDialogClose_Types),
    filter((action) => !action.save && state$.value.filesAdd.fileUuid !== ""),
    map(() => {
        return RemoveFile({
            name: "-",
            uuid: state$.value.filesAdd.fileUuid,
            deleted: false,
            usage: 0,
            type: "-",
            downloading: false,
        });
    })
);

const handlerUploadChunkAction = (action$: ActionsObservable<AddDialogActionTypes>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(UPLOAD_CHUNK_ACTION_NAME),
    pipe(
        filter(() => state$.value.filesAdd.open),
        filter(() => state$.value.filesAdd.fileUuid !== ""),
        map((action) => action as UploadChunk_Types),
        concatMap(async (action) => {
            const start = CHUNK_SIZE * action.chunk;
            const end = start + CHUNK_SIZE;
            const blob = state$.value.filesAdd.file?.slice(start, end);

            if (!blob) {
                return onError({
                    errorNumber: UnknownErrorNum,
                    description: "Unknown error"
                });
            }
            const chunk = await ToBase64(blob);
            console.log("blob size: ", blob.size);

            return sendJsonApiRequest({
                name: UPLOAD_CHUNK,
                url: config.apiUrl + "/v2/file/" + action.file + "/chunk",
                method: "POST",
                headers: [],
                requireAuth: true,
                responseVariables: [action.file],
                body: JSON.stringify({
                    "data": {
                        "chunk": chunk,
                    }
                }),
            })
        })
    )
);

export const AddDialogEpic = (action$: ActionsObservable<AddDialogActionTypes>, state$: StateObservable<StoreState>) => merge(
    handlerSaveAction(action$, state$),
    handlerUploadChunkAction(action$, state$),
    handlerCancelAction(action$, state$),
)