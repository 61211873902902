const Permissions = {
  Users: 'u',
  Statistics: 's',
  Prices: 'p',
  Machines: 'm',
  Events: 'e',
  LifeLink: 'l',
  Never: 'Neverwriteerrormessagestired.(xkcd:2200)',
  Download: 'd',
};

export default Permissions;
