import {FilesPageView, FilesPageViewDispatches, FilesPageViewStates} from "./View";
import {StoreState} from "../../StoreState";
import {connect} from "react-redux";
import {OpenAddDialog} from "./AddDialog/Actions";
import {DownloadFile, GetFiles, RemoveFile} from "../../data/files/Actions";

export interface FilesPageProps {
}

const FilesPageStates: (state: StoreState, props: FilesPageProps) => FilesPageViewStates =
    (state, props) => {
        return {
            files: state.files.files.sort((file1, file2) => {
                return file1.name.localeCompare(file2.name);
            }),
        }
    };

const FilesPageDispatches: (dispatch: any, props: FilesPageProps) => FilesPageViewDispatches =
    (dispatch, props) => {
        return {
            onAdd: () => dispatch(OpenAddDialog()),
            pull: () => dispatch(GetFiles()),
            onFileRemove: (file) => dispatch(RemoveFile(file)),
            onDownload: (file) => dispatch(DownloadFile(file)),
        }
    };

export const FilesPage = connect(
    FilesPageStates,
    FilesPageDispatches,
)(FilesPageView);
