import {Machine, Organisation} from "../OrganisationState";
import {CreateOrganisationAction, CreateOrganisationResponseAction} from "./create";
import {
    DialogLocationResultAction,
    LocationResultAction,
    MachineResultAction,
    MoveMachineResponseAction,
    OrganisationResultAction
} from "./reponses";

export * from './update';

export interface OrganisationReceiveAction {
    type: "[org] Requesting list of organisations"
}
export const ORGANISATION_RECEIVE_ACTION = "[org] Requesting list of organisations";

export interface OrganisationSelectAction {
    type: "[org] organisation select";
    organisation: Organisation | null;
    hasChanged: boolean;
}
export const ORGANISATION_SELECT_ACTION = "[org] organisation select";

export interface MoveMachineAction {
    type: "[org] move machine to other location";
    machine: Machine;
    destination: string;
}
export const MOVE_MACHINES_ACTION = "[org] move machine to other location";

export interface OpenLocationChooser {
    type: "[org] open location chooser";
    machine: Machine;
    organisation: Organisation;
}

export const OPEN_LOCATION_CHOOSER = "[org] open location chooser";

export interface CloseLocationChooser {
    type: "[org] close location chooser";
}

export const CLOSE_LOCATION_CHOOSER = "[org] close location chooser";


export type OrganisationAction =
    OrganisationSelectAction
    | OrganisationResultAction
    | LocationResultAction
    | MachineResultAction
    | OrganisationReceiveAction
    | CreateOrganisationAction
    | CreateOrganisationResponseAction
    | MoveMachineAction
    | MoveMachineResponseAction
    | OpenLocationChooser
    | CloseLocationChooser
    | DialogLocationResultAction;


export function openLocationChooser(machine: Machine, organisation: Organisation): OpenLocationChooser {
    return {
        type: "[org] open location chooser",
        organisation: organisation,
        machine: machine,
    }
}

export function closeLocationChooser(): CloseLocationChooser {
    return {
        type: "[org] close location chooser"
    }
}