
import {snacks} from "./SnackReducers";
import {machineAdministration} from "../data/organisations/reducers/MachineReducers";
import {notifications} from "../notifications/reducers";
import {auth} from "../auth/reducers";
import {newDialog} from "../newDialog/reducers";
import {configDialogReducer} from "../pages/Machines/ConfigDialog/Reducers";
import {machineReducer} from "../data/machines/Reducers";
import {FilesPageReducer} from "../pages/Files/Reducer";
import {AddDialogReducer} from "../pages/Files/AddDialog/Reducer";
import {FilesReducer} from "../data/files/Reducer";
import {AutoActivateReducer} from "../pages/Machines/AutoActivate/Reducer";
import {TableFilterReducer} from "../pages/Machines/Filter/Reducer";

export const reducers = {
    newDialog,
    snacks,
    machineAdministration,
    machineCollection: machineReducer,
    notifications,
    auth,
    configDialog: configDialogReducer,
    filesPage: FilesPageReducer,
    filesAdd: AddDialogReducer,
    files: FilesReducer,
    autoActivate: AutoActivateReducer,
    tableFilter: TableFilterReducer,
};
