import React, {useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  Divider,
  Button,
  makeStyles,
  Snackbar,
  SnackbarContent,
  IconButton,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import {changePassword} from '../../auth/managers/sessionManager';

const useStyles = makeStyles(theme => ({
  changePasswordContainer: {
    maxWidth: "444px",
  },
  snackbar: {
    backgroundColor: green[600],
  },
  snackbarIcon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center',
  },
}));
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.,<>/?;:'"[\]{}\\|()\-_=+~`!@#$%^&*€]).{7,42}$/;

const Settings = props => {
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [updating, setUpdating] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    setCurrentPasswordError("");
    setNewPasswordError("");
    setConfirmPasswordError("");

    if(!newPassword.match(passwordRegex)) {
      setNewPasswordError("Your password must be at least 7 characters long, contain at least one number, a special character and have a mixture of uppercase and lowercase letters.");
      setUpdating(false);
      return false;
    }


    if(newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords don't match");
      return false;
    }

    setUpdating(true);
    changePassword(currentPassword, newPassword)
      .then(() => {
        setUpdating(false);
        console.log("Password changed successfully");
        setSuccessSnackbar("Password changed successfully");
      })
      .catch((error) => {
        console.log("error: " + error)
        if(error[0] === 8) {
          setCurrentPasswordError("Wrong password");
        }
        setUpdating(false);
      });
  }

  const classes = useStyles();
  return(
    <MuiThemeProvider theme={createTheme({})}>
      <Box m={1}>
        <Card>
          <CardContent>
            <div className={classes.changePasswordContainer}>
              <Typography variant="h5" component="h2" gutterBottom>
                Change password
              </Typography>
              <Divider />
              <form noValidate onSubmit={handleSubmit}>
                <div><TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Current password"
                  type="password"
                  id="current-password"
                  autoComplete="current-password"
                  InputLabelProps={{ required: false }}
                  value={currentPassword}
                  onChange={event => setCurrentPassword(event.target.value)}
                  error={!!currentPasswordError}
                  helperText={currentPasswordError}
                /></div>
                <div><TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="New password"
                  type="password"
                  id="new-password"
                  autoComplete="new-password"
                  InputLabelProps={{ required: false }}
                  value={newPassword}
                  onChange={event => setNewPassword(event.target.value)}
                  error={!!newPasswordError}
                  helperText={newPasswordError}
                /></div>
                <div><TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Confirm new password"
                  type="password"
                  id="confirm-password"
                  autoComplete="new-password"
                  InputLabelProps={{ required: false }}
                  value={confirmPassword}
                  onChange={event => setConfirmPassword(event.target.value)}
                  error={!!confirmPasswordError}
                  helperText={confirmPasswordError}
                /></div>
                <Box mt={2}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={updating}
                  >
                    Update password
                  </Button>
                </Box>
              </form>
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={!!successSnackbar}
                autoHideDuration={6000}
                onClose={()=>setSuccessSnackbar("")}
              >
              <SnackbarContent
                className={classes.snackbar}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar" className={classes.snackbarMessage}>
                    <CheckCircleIcon className={classes.snackbarIcon} />
                    {successSnackbar}
                  </span>
                }
                action={[
                  <IconButton key="close" aria-label="close" color="inherit" onClick={()=>setSuccessSnackbar("")}>
                    <CloseIcon className={classes.icon} />
                  </IconButton>,
                ]}
                />
              </Snackbar>
            </div>
          </CardContent>
        </Card>
      </Box>
    </MuiThemeProvider>
  );
};

export default Settings;
