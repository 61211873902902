import {HeaderView, HeaderViewDispatches, HeaderViewStates} from "./View";
import {connect} from "react-redux";
import {StoreState} from "../../StoreState";
import {useLocation} from "react-router-dom";


export interface HeaderProps {
}

const HeaderStates: (state: StoreState, props: HeaderProps) => HeaderViewStates =
    (state, props) => {
        return {
            loggedIn: state.auth.validUser,
        }
    };

const HeaderDispatches: (dispatch: any, props: HeaderProps) => HeaderViewDispatches =
    (dispatch, props) => {
        return {}
    };

export const Header = connect(
    HeaderStates,
    HeaderDispatches,
)(HeaderView);