import {FC, memo} from "react";
import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    LinearProgress,
    TextField, Tooltip
} from "@material-ui/core";
import {FullWidthTextField} from "../../../components/Forms/FullWidthTextField/FullWidthTextField";

export interface AddDialogViewStates {
    open: boolean,
    saving: boolean,
    progress: number,
    name: string,
    nameError: string,
    fileType: string,
    fileHasError: boolean,
    fileErrorText: string,
}

export interface AddDialogViewDispatches {
    onNameChange: (value: string) => void,
    onClose: () => void,
    onSave: () => void,
    onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

export type AddDialogViewProps =
    AddDialogViewStates
    & AddDialogViewDispatches
    ;

export const AddDialogView: FC<AddDialogViewProps> = memo(({
                                                               open,
                                                               saving,
                                                               progress,
                                                               name,
                                                               nameError,
                                                               fileType,
                                                               fileHasError,
                                                               fileErrorText,
                                                               onNameChange,
                                                               onClose,
                                                               onSave,
                                                               onFileSelect,
                                                           }) => {
        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Add file</DialogTitle>
                <DialogContent>
                    <Box hidden={saving}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FullWidthTextField label={"Name"}
                                                    name={"name"}
                                                    value={name}
                                                    onChange={onNameChange}
                                                    error={nameError}
                                />
                            </Grid>

                            <Grid item xs={12} sm={5} style={{display: "flex", alignItems: "flex-end"}}>
                                <Button variant="contained" component="label" fullWidth>
                                    Upload File
                                    <input
                                        type="file"
                                        onChange={onFileSelect}
                                        hidden
                                    />
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={7} style={{display: "flex", alignItems: "center"}}>
                                <Tooltip title={fileErrorText} placement={"bottom-start"} arrow>
                                    <TextField
                                        fullWidth
                                        name={"type"}
                                        label={fileType ? "" : "File type"}
                                        value={fileType}
                                        disabled
                                        error={fileHasError}
                                        margin={"dense"}
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box hidden={!saving}>
                        <LinearProgress variant={progress > 0 ? "determinate" : "indeterminate"} value={progress}/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onSave} color={"primary"} disabled={saving}>Save</Button>
                </DialogActions>
            </Dialog>
        );
    }
)