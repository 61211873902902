import {OrganisationState} from "../OrganisationState";
import {
    CloseLocationChooser,
    OpenLocationChooser,
    OrganisationAction,
    OrganisationSelectAction,
} from "../actions";
import {
    DialogLocationResultAction,
    LocationResultAction,
    MachineResultAction,
    MoveMachineResponseAction,
    OrganisationResultAction
} from "../actions/reponses";

const initState: OrganisationState = {
    organisations: [],
    locations: [],
    machines: [],
    currentOrganisation: null,
    dialog: {
        open: false,
        organisation: null,
        locations: [],
        machine: null,
    },
};

function selectOrganisation(oldState: OrganisationState, action: OrganisationSelectAction): OrganisationState {
    const newState = Object.assign({}, oldState, {
        currentOrganisation: action.organisation,
    });

    if (action.hasChanged) {
        newState.locations = [];
        newState.machines = [];
    }
    return newState;
}

function updateOrganisations(oldState: OrganisationState, action: OrganisationResultAction): OrganisationState {
    return Object.assign({}, oldState, {
        organisations: action.organisations,
    });
}

function updateLocations(oldState: OrganisationState, action: LocationResultAction): OrganisationState {
    return Object.assign({}, oldState, {
        locations: action.locations,
        machines: [],
    });
}

function addMachines(oldState: OrganisationState, action: MachineResultAction): OrganisationState {
    return Object.assign({}, oldState, {
        machines: [
            ...oldState.machines,
            ...action.machines
        ]
    });
}

function moveMachine(oldState: OrganisationState, action: MoveMachineResponseAction): OrganisationState {
    const newState: OrganisationState = Object.assign({}, oldState, {
        dialog: Object.assign({}, oldState.dialog, {
            open: false,
        })
    });
    const location = newState.locations.find((location) => location.uuid === action.destination);

    if (location === undefined) {
        newState.machines = oldState.machines.filter((machine) => {
            return machine.uuid !== action.machine.uuid;
        });
    } else {
        newState.machines = oldState.machines.map((machine) => {
            if (machine.uuid === action.machine.uuid && location) machine.location = location;
            return machine;
        });
    }

    return newState;
}

function openLocationChooser(oldState: OrganisationState, action: OpenLocationChooser): OrganisationState {
    return Object.assign({}, oldState, {
        dialog: Object.assign({}, oldState.dialog, {
            open: true,
            machine: action.machine,
            organisation: action.organisation,
            locations: []
        }),
    });
}

function closeLocationChooser(oldState: OrganisationState, action: CloseLocationChooser): OrganisationState {
    return Object.assign({}, oldState, {
        dialog: Object.assign({}, oldState.dialog, {
            open: false,
            machine: null,
            organisation: null,
            locations: []
        }),
    });
}

function updateDialogLocation(oldState: OrganisationState, action: DialogLocationResultAction): OrganisationState {
    return Object.assign({}, oldState, {
        dialog: Object.assign({}, oldState.dialog, {
            locations: action.locations,
        }),
    });
}

function machineAdministration(state: OrganisationState = initState, action: OrganisationAction): OrganisationState {
    switch (action.type) {
        case "[org] organisation select":
            return selectOrganisation(state, action);

        case "[org] organisation results":
            return updateOrganisations(state, action);

        case "[org] location results":
            return updateLocations(state, action);

        case "[org] machine results":
            return addMachines(state, action);

        case "[org] Machine transfer succeed":
            return moveMachine(state, action);

        case "[org] open location chooser":
            return openLocationChooser(state, action);

        case "[org] dialog location results":
            return updateDialogLocation(state, action);

        case "[org] close location chooser":
            return closeLocationChooser(state, action);

        case "[org] move machine to other location":
        case "[org] organisation create":
        case "[org] organisation has been created":
        case "[org] Requesting list of organisations":
        default:
            return state;
    }
}

export {machineAdministration}