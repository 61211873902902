import {postJson, getJson} from '../../managers/apiManager';

function logout(sendRest = true) {
  console.log("Logging out...");
  const localStorageLogout = () => {
    // localStorage.removeItem("authenticated");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("authPermissions");
    localStorage.removeItem("locationSelect");
    localStorage.removeItem("organisations");
  };
  if(sendRest) {
    return postJson("/logout")
    .then(response => {
      localStorageLogout();
      return Promise.resolve(true);
    })
    .catch(error => {
      localStorageLogout();
      return Promise.resolve(false);
    });
  } else {
    localStorageLogout();
  }
}

function login(email, password) {
  return postJson("/login", JSON.stringify({email, password}))
  .then(response => {
    if (response.success) {
      console.log("Login success!");
      localStorage.setItem('sessionId', response.session);
      localStorage.setItem('authPermissions', response.details.permissions);
      localStorage.setItem('authenticated', true);
      return getJson("/organisations")
      .then((response) => {
        localStorage.setItem("organisations", JSON.stringify(response.details));
        return Promise.resolve();
      });
    } else if(response.error) {
      return Promise.reject(response.details);
    } else {
      return Promise.reject("Unknown error");
    }
  });
}

function isAuthenticated() {
  return JSON.parse(localStorage.getItem('authenticated')) || false; // Json parse is used because the boolean is stored as string
}

function isStandalone() {
  return JSON.parse(localStorage.getItem('standalone')) || false; // Json parse is used because the boolean is stored as string
}

function getAuthPermissions() {
  return localStorage.getItem("authPermissions") || "";
}

function getSessionId() {
  return localStorage.getItem("sessionId") || "";
}

function getOrganisations() {
  let organisations = JSON.parse(localStorage.getItem('organisations')) || {};
  return organisations;
}

function changePassword(password_current, password_new) {
  return postJson("/change-password", JSON.stringify({password_current, password_new}))
  .then(response => {
    if (response.success) {
      console.log("Password change success!");
      return Promise.resolve();
    } else if(response.error) {
      return Promise.reject(response.details);
    } else {
      return Promise.reject("Unknown error");
    }
  });
}

export {logout, login, isAuthenticated, isStandalone, getAuthPermissions, getSessionId, getOrganisations, changePassword};
