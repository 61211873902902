import {
    MoveMachineAction,
    ORGANISATION_RECEIVE_ACTION,
    OrganisationReceiveAction,
    OrganisationSelectAction
} from "./index";
import {Location, Machine, Organisation} from "../OrganisationState";
import {LocationResponseDTO, MachineResponseDTO, OrganisationResponseDTO} from "../../../api/ApiResponses";
import {
    DialogLocationResultAction,
    LocationResultAction,
    MachineResultAction,
    MoveMachineResponseAction,
    OrganisationResultAction
} from "./reponses";

export function retreiveOrganisationList(): OrganisationReceiveAction {
    return {
        type: ORGANISATION_RECEIVE_ACTION,
    }
}

export function setCurrentOrganisations(organisation: Organisation | null, hasChanged: boolean): OrganisationSelectAction {
    return {
        type: "[org] organisation select",
        organisation: organisation,
        hasChanged,
    };
}

export function updateOrganisations(response: OrganisationResponseDTO): OrganisationResultAction {
    return {
        type: "[org] organisation results",
        organisations: Object.entries(response.data).map(([uuid, name]) => {
            return {uuid, name};
        }),
    };
}

export function updateLocations(response: LocationResponseDTO): LocationResultAction {
    return {
        type: "[org] location results",
        locations: Object.entries(response.data).map(([uuid, name]) => {
            return {
                uuid,
                name
            };
        })
    };
}

export function updateDialogLocations(response: LocationResponseDTO): DialogLocationResultAction {
    return {
        type: "[org] dialog location results",
        locations: Object.entries(response.data).map(([uuid, name]) => {
            return {
                uuid,
                name
            };
        })
    };
}

export function addMachines(response: MachineResponseDTO, location: Location): MachineResultAction {
    return {
        type: "[org] machine results",
        machines: response.data.map(({uuid, outOfService}) => {
            return {
                uuid,
                location,
                enabled: outOfService,
            };
        })
    };
}

export function moveMachine(machine: Machine, destination: string): MoveMachineAction {
    return {
        type: "[org] move machine to other location",
        machine,
        destination,
    }
}

export function moveMachineSucceeded(machine: Machine, destination: string): MoveMachineResponseAction {
    return {
        type: "[org] Machine transfer succeed",
        machine,
        destination,
    }
}