import {FC, memo, useEffect} from "react";
import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import {useHistory} from "react-router-dom";

export interface AutoActivateViewStates {
    open: boolean,
    redirect: boolean,
    token: string,
    machineKey: string,
}

export interface AutoActivateViewDispatches {
    activate: () => void,
    cancel: () => void,
}

export type AutoActivateViewProps =
    AutoActivateViewStates
    & AutoActivateViewDispatches
    ;

export const AutoActivateView: FC<AutoActivateViewProps> = memo(({
                                                                     open,
                                                                     token,
                                                                     machineKey,
                                                                     activate,
                                                                     redirect,
                                                                     cancel,
                                                                 }) => {
        const history = useHistory();
        useEffect(() => {
            if (redirect) {
                history.push("/machines");
            }
        }, [history, redirect]);

        return (
            <Dialog open={open}>
                <DialogTitle>Activation</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure to activate the following machine?</DialogContentText>
                    <TextField disabled={true}
                               value={token}
                               name={"token"}
                               id={"token"}
                               size={"small"}
                               label={"Token"}
                               margin="dense"
                               variant="outlined"
                               fullWidth

                    />
                    <TextField disabled={true}
                               value={machineKey}
                               name={"key"}
                               id={"key"}
                               size={"small"}
                               label={"Key"}
                               margin="dense"
                               variant="outlined"
                               fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancel}>Cancel</Button>
                    <Button onClick={activate} color={"primary"}>Activate</Button>
                </DialogActions>
            </Dialog>
        );
    }
)