export interface BatchCallAction {
    type: "[batch] call",
    actions: any[]
}
export const BATCH_CALL_ACTION = "[batch] call";

export function callBatchActions(actions: any[]): BatchCallAction {
    return {
        type: "[batch] call",
        actions,
    }
}

export type BatchActions = BatchCallAction;