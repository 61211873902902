import React, {useState, useEffect} from 'react';
import {
  Box,
  Paper,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
} from '@material-ui/core';
import SuccessSnackbar from '../../components/Snackbars_old/SuccessSnackbar';
import ErrorSnackbar from '../../components/Snackbars_old/ErrorSnackbar';
import {getJson, postJson} from '../../managers/apiManager';

const baseUrl = "https://jenkins.office.kaplock.nl:1338";

const LifeLink = props => {
  const [slaves, setSlaves] = useState([]);
  const [selectedSlave, setSelectedSlave] = useState(-1);
  const [selectedCoins, setSelectedCoins] = useState(1);
  const [ejectOpen, setEjectOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState("");
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    getJson(baseUrl + "/slaves")
    .then(response => {
      if(response.success) {
        setSlaves(response.slaves);
      }
    });
  }, []);

  function getSlaveById(id) {
    return slaves.filter(slave => slave.id === id)[0] || "";
  }

  function ejectCoins(id, coins) {
    setBusy(true);
    postJson(baseUrl + "/slave/" + id + "/eject/" + coins)
    .then(response => {
      if(response.success) {
        setSuccessSnackbar("Successfully ejected " + coins + " coins");
        setBusy(false);
      } else {
        // setErrorSnackbar("Something went wrong ejecting coins");
        setErrorSnackbar(response.details[1]);
        setBusy(false);
      }
    });
  }

  return (
    <Box m={1}>
      <Paper>
        <List subheader={<ListSubheader>Slaves</ListSubheader>}>
          {slaves.map(slave => (
            <div key={slave.id}>
              <Divider />
              <ListItem button disabled={busy} onClick={() => {
                  setSelectedSlave(slave.id);
                  setSelectedCoins(1);
                  setEjectOpen(true);
                }}>
                <ListItemText primary={slave.name} />
              </ListItem>
            </div>
          ))}
        </List>
      </Paper>
      <Dialog open={ejectOpen} onClose={() => setEjectOpen(false)}>
        <DialogTitle>How many coins to eject?</DialogTitle>
        <Box m={1}>
          <form onSubmit={event => {
            event.preventDefault();
            setEjectOpen(false);
            setConfirmOpen(true);
          }}>
            <TextField
              id="outlined-number"
              label="Number"
              type="number"
              margin="normal"
              variant="outlined"
              InputProps={{ inputProps: {min: 1} }}
              fullWidth
              autoFocus
              value={selectedCoins}
              onChange={event => setSelectedCoins(event.target.value)}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
            >
              Eject
            </Button>
          </form>
        </Box>
      </Dialog>
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirm coin ejection</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to eject {selectedCoins} coins from {getSlaveById(selectedSlave).name}. Are you sure? You cannot cancel once started.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmOpen(false)} color="primary">
              No
            </Button>
            <Button onClick={() => {setConfirmOpen(false); ejectCoins(selectedSlave, selectedCoins);}} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
      </Dialog>
      <SuccessSnackbar open={!!successSnackbar} message={successSnackbar} handleClose={() => setSuccessSnackbar("")} />
      <ErrorSnackbar open={!!errorSnackbar} message={errorSnackbar} handleClose={() => setErrorSnackbar("")} />
    </Box>
  )
};

export default LifeLink;
