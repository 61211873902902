import config from '../config';
import {isAuthenticated, isStandalone, getSessionId, logout} from '../auth/managers/sessionManager';

function postJson(url, body = "") {
  return jsonRequest(url, "POST", body);
}

function getJson(url) {
  return jsonRequest(url, "GET");
}

function jsonRequest(url, method, body = undefined) {
  let headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
  if(isAuthenticated())
    headers["session-id"] = getSessionId();
  if(isStandalone())
    headers["standalone"] = true;
  const requestOptions = {
    method,
    headers,
    body,
  };
  if(!url.match(/https?:\/\//)) {
    url = config.apiUrl + url;
  }
  return fetch(url, requestOptions)
    .then(response => handleResponse(response))
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 400 && data.details[0] === 4) {
        console.log("Not logged in! Logging out.");
        logout(false);
        return Promise.reject(data.details);
      }
    }
    return data;
  }).catch(reason => {
    return Promise.reject(reason);
  });
}

export {postJson, getJson};
