export interface CreateOrganisationAction {
    type: "[org] organisation create";
    information: OrganisationInformation;
}
export const CREATE_ORGANISATION_ACTION = "[org] organisation create";

export interface CreateOrganisationResponseAction {
    type: "[org] organisation has been created";
}
export const CREATE_ORGANISATION__RESPONSE_ACTION = "[org] organisation has been created";


export interface OrganisationInformation {
    name: string;
    street: string;
    streetNumber: number;
    postalCode: string;
    city: string;
    countryCode: string;
    others: string;
    createLocation: boolean;
}

export interface OrganisationInformationDTO {
    name: string;
    street: string;
    street_number: number;
    postal_code: string;
    city: string;
    country_code: string;
    others: string;
    create_location: boolean;
}

export function createOrganisation(information: OrganisationInformation): CreateOrganisationAction {
    return {
        type: "[org] organisation create",
        information
    }
}

export function onCreateOrganisationResponse():CreateOrganisationResponseAction {
    return {
        type: "[org] organisation has been created",
    }
}