import {FC} from "react";
import React, {Fragment} from "react";
import {Login} from "./Login";
import {useValidUser} from "../hooks/Login";

export const LoggedIn: FC = ({children}) => {
    const loggedIn = useValidUser();
    if (!loggedIn) {
        return <Login />;
    }
    return <Fragment>{children}</Fragment>;
};
