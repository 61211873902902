import {Machine, Token} from "./MachinesState";

export const MACHINE_GET_ACTION = "[mac] Request machine data";

export interface MachineGetAction {
    type: typeof MACHINE_GET_ACTION;
}

export const MACHINE_FETCHED_ACTION = "[mac] Machine data fetched";

export interface MachineFetchedAction {
    type: typeof MACHINE_FETCHED_ACTION;
    machines: Machine[]
}

export const MACHINE_GET_TOKEN_ACTION = "[mac] Request token data";

export interface MachineGetTokenAction {
    type: typeof MACHINE_GET_TOKEN_ACTION;
}

export const MACHINE_FETCHED_TOKEN_ACTION = "[mac] Machine token fetched";

export interface MachineFetchedTokenAction {
    type: typeof MACHINE_FETCHED_TOKEN_ACTION;
    tokens: Token[]
}

export const MACHINE_EDIT_TOKEN_ACTION = "[mac] Edit token";

export interface MachineEditTokenAction {
    type: typeof MACHINE_EDIT_TOKEN_ACTION;
    token: Token;
}

export const MACHINE_EDITABLE_TOKEN_ACTION = "[mac] Change edit ability token";

export interface MachineEditableTokenAction {
    type: typeof MACHINE_EDITABLE_TOKEN_ACTION;
    token: Token;
}

export type MachineActions =
    MachineGetAction |
    MachineFetchedAction |
    MachineGetTokenAction |
    MachineFetchedTokenAction |
    MachineEditTokenAction |
    MachineEditableTokenAction;