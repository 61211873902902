import {
    ADD_STATE_FILTER_TABLE_FILTER_ACTION_NAME,
    AddStateFilter_TableFilter_Types,
    CHANGE_STATE_POPUP_ACTION_NAME,
    ChangeStatePopup_Types,
    DELETE_STATE_FILTER_TABLE_FILTER_ACTION_NAME,
    DeleteStateFilter_tableFilter_Types,
    TABLE_FILTER_CHANGE_SEARCH_FIELD_ACTION_NAME,
    TableFilterChangeSearchField_Types
} from "./Types";
import {State} from "./State";


export function AddStateFilter(filter: State[]): AddStateFilter_TableFilter_Types {
    return {
        type: ADD_STATE_FILTER_TABLE_FILTER_ACTION_NAME,
        filter,
    }
}

export function DeleteStateFilter(state: State): DeleteStateFilter_tableFilter_Types {
    return {
        type: DELETE_STATE_FILTER_TABLE_FILTER_ACTION_NAME,
        state,
    }
}

export function ChangeStatePopup(open: boolean): ChangeStatePopup_Types {
    return {
        type: CHANGE_STATE_POPUP_ACTION_NAME,
        open,
    }
}

export function ChangeSearchFieldTableFilter(search: string): TableFilterChangeSearchField_Types {
    return {
        type: TABLE_FILTER_CHANGE_SEARCH_FIELD_ACTION_NAME,
        search,
    }
}