import {
    FILES_DOWNLOAD_ACTION_NAME,
    FILES_REMOVED_CONFIRMATION_ACTION_NAME,
    FilesDownload_Types,
    FilesRemovedConfirmation_Types,
    GET_FILES_ACTION_NAME,
    GetFiles_Types,
    GetFilesDTO,
    REMOVE_FILE_ACTION_NAME,
    RemoveFile_Types,
    SET_FILES_ACTION_NAME,
    SET_FILES_Types
} from "./Types";
import {File} from "./State";

export function GetFiles(): GetFiles_Types {
    return {
        type: GET_FILES_ACTION_NAME,
    }
}

export function SetFiles(data: GetFilesDTO): SET_FILES_Types {
    return {
        type: SET_FILES_ACTION_NAME,
        data,
    }
}

export function RemoveFile(file: File): RemoveFile_Types {
    return {
        type: REMOVE_FILE_ACTION_NAME,
        file,
    }
}
export function DownloadFile(file: File): FilesDownload_Types {
    return {
        type: FILES_DOWNLOAD_ACTION_NAME,
        file,
    }
}

export function ConfirmFilesRemoved(): FilesRemovedConfirmation_Types {
    return {
        type: FILES_REMOVED_CONFIRMATION_ACTION_NAME,
    }
}

