/**
 * When user request to login
 */
export interface AuthLogin {
    type: "[auth] user tries to log in";
    email: string;
    password: string;
}

/**
 * When login was successfully
 */
export const AUTH_SUCCESS = "[auth] user successfully logged in";
export interface AuthSuccess {
    type: typeof AUTH_SUCCESS;
    permissions: string;
}


/**
 * When login was failed
 */
export interface AuthFailed {
    type: "[auth] user failed to logged in";
}

/**
 * When user request to logout
 */
export const AUTH_LOGOUT = "[auth] logged-out";
export interface AuthLogout {
    type: typeof AUTH_LOGOUT;
    why: string
}

/**
 * When new session id is available
 */
export interface AuthNewSession {
    type: "[auth] New session";
    session: string;
}

export const AUTH_NEW_SESSION = "[auth] New session";

/**
 * Get login action
 * @param password User's password
 * @param email User's email
 */
export function userLoggedIn(email: string, password: string): AuthLogin {
    return {
        type: "[auth] user tries to log in",
        email,
        password,
    };
}

/**
 * Get success login action
 */
export function userSuccessfullyLoggedIn(permissions: string): AuthSuccess {
    return {
        type: "[auth] user successfully logged in",
        permissions,
    };
}

/**
 * Get success login action
 */
export function userFailedLogin(): AuthFailed {
    return {
        type: "[auth] user failed to logged in"
    };
}

export function newSessions(session: string): AuthNewSession {
    return {
        type: "[auth] New session",
        session
    }
}

export function userLogout(why: string): AuthLogout {
    return {
        type: "[auth] logged-out",
        why
    }
}

export type AuthActions = AuthLogin | AuthLogout | AuthSuccess | AuthFailed | AuthNewSession;