import React, {FC, useEffect} from "react";
import {Box, createStyles, TableContainer, Theme} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {MachineList} from "./Lists/MachineList";
import {NewDialog} from "../../newDialog/components";
import {TokenList} from "./Lists/TokenList";
import {withStyles} from "@material-ui/core/styles";
import {ConfigDialog} from "./ConfigDialog/Controller";
import {useLocation} from "react-router-dom";
import {AutoActivate} from "./AutoActivate/Controller";
import {TableFilter} from "./Filter/Controller";
import {Machine, Token} from "../../data/machines/MachinesState";


export const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "rgba(0,0,0,0.06)",
        },
    }),
)(TableCell);

export const AutoHideTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "rgba(0,0,0,0.06)",
            [theme.breakpoints.down('sm')]: {
                display: "None",
            }
        },
        body: {
            [theme.breakpoints.down('sm')]: {
                display: "None",
            }
        },
    }),
)(TableCell);

export interface MachineViewStates {
    machines: Machine[];
    tokens: Token[];
}

export interface MachineViewDispatches {
    refresh: () => void,
    onAutoActivate: (key: string, token: string) => void,
}

export type MachineViewProps = MachineViewStates & MachineViewDispatches;

const MachineView: FC<MachineViewProps> = ({
                                               refresh,
                                               machines,
                                               tokens,
                                               onAutoActivate
                                           }) => {
    const search = useLocation().search;
    useEffect(() => {
        const key = new URLSearchParams(search).get("key");
        const token = new URLSearchParams(search).get("token");

        console.log(key, token);
        if (key && token) onAutoActivate(key, token);
    }, [search, onAutoActivate]);

    useEffect(() => {
        refresh();

        const timer = setInterval(refresh, 5000);
        return () => {
            clearInterval(timer);
        }
    }, [refresh]);

    return (
        <Box m={1}>
            <Paper>
                <TableFilter/>
                <TokenList tokens={tokens}/>

                <TableContainer component={"div"}>
                    <Table size={"small"}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell align="center">Terminal id</StyledTableCell>
                                <AutoHideTableCell align="right">Terminal serial</AutoHideTableCell>
                            </TableRow>
                        </TableHead>
                        <MachineList machines={machines}/>
                    </Table>
                </TableContainer>
            </Paper>
            <ConfigDialog/>
            <AutoActivate/>
        </Box>
    );
};

export default MachineView;