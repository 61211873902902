import {Error, UnknownErrorNum, WipErrorDescr, WipErrorNum} from "../NotificationsState"

/**
 * Data Tranfer Object for backend API to local
 */
export interface ErrorApiDTO {
    error: [number, string]
}

/**
 * When (sub)system detected an error
 */
export const ERROR_FIRED = "[notifications] error fired";

export interface ErrorFired {
    type: "[notifications] error fired"
    error: Error,
}

/**
 * When error is confirmed (by user or timeout)
 */
export interface ErrorConfirmed {
    type: "[notifications] error confirmed"
}


/**
 * Get error action
 * @param error Error created in front-end
 */
export function onError(error: Error): ErrorFired {
    return {
        type: "[notifications] error fired",
        error: error,
    }
}


/**
 * Get unknown error action
 */
export function onUnknownError(): ErrorFired {
    return {
        type: "[notifications] error fired",
        error: {
            errorNumber: UnknownErrorNum,
            description: "Unknown error",
        },
    }
}

/**
 * Get error action from an server error
 * @param error Error from the backend
 */
export function onServerError(error: ErrorApiDTO): ErrorFired {
    console.log(error);

    switch (error.error[0]) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
            return onError({
                errorNumber: error.error[0],
                description: error.error[1]
            });

        default:
            return onUnknownError();
    }
}

/**
 * Get WIP action
 */
export function onWIP(): ErrorFired {
    return onError({
        errorNumber: WipErrorNum,
        description: WipErrorDescr,
    });
}

/**
 * Confirm the current error.
 */
export function confirmError(): ErrorConfirmed {
    return {
        type: "[notifications] error confirmed"
    }
}

export type ErrorActions = ErrorFired | ErrorConfirmed;