import {NotificationsState} from "../NotificationsState";
import {ErrorActions, ErrorConfirmed, ErrorFired} from "../actions";

const initState: NotificationsState = {
    error: null,
    showError: false
};

function setError(state: NotificationsState, action: ErrorFired): NotificationsState {
    return Object.assign({}, state, {
        error: action.error,
        showError: true,
    });
}

function removeError(state: NotificationsState, action: ErrorConfirmed): NotificationsState {
    return Object.assign({}, state, {
        showError: false
    });
}

export function notifications(state: NotificationsState = initState, action: ErrorActions): NotificationsState {
    switch (action.type) {
        case "[notifications] error confirmed":
            return removeError(state, action);

        case "[notifications] error fired":
            return setError(state, action);

        default:
            return state;
    }
}