import {Machine, Location, Organisation} from "../OrganisationState";

export interface OrganisationResultAction {
    type: "[org] organisation results";
    organisations: Organisation [];
}

export const ORGANISATION_RESULT_ACTION = "[org] organisation results";

export interface LocationResultAction {
    type: "[org] location results";
    locations: Location [];
}

export const LOCATION_RESULT_ACTION = "[org] location results";

export interface DialogLocationResultAction {
    type: "[org] dialog location results";
    locations: Location [];
}

export const DIALOG_LOCATION_RESULT_ACTION = "[org] dialog location results";

export interface MachineResultAction {
    type: "[org] machine results";
    machines: Machine [];
}

export interface MoveMachineResponseAction {
    type: "[org] Machine transfer succeed";
    machine: Machine;
    destination: string;
}

export const MOVE_MACHINES_RESPONSE_ACTION = "[org] Machine transfer succeed";