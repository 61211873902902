import {Request, RequestNames, ResponseApiDTO} from "../ApiState";
import {Response} from "../ApiResponses";

/**
 * When system request a call to an api
 */
export interface ApiRequestAction {
    type: "[api] send a request";
    request: Request;
}

export const API_REQUEST = "[api] send a request";

/**
 * When system received the response from API call
 */
export interface ApiResponseAction {
    type: "[api] receive a response";
    response: Response;
    variables: any[];
    newSession: string | undefined;
}

export const API_RESPONSE = "[api] receive a response";

export type ApiActions = ApiRequestAction | ApiResponseAction;

/**
 * Get API Request actions
 */
export function sendApiRequest(request: Request): ApiRequestAction {
    return {
        type: API_REQUEST,
        request
    }
}

/**
 * Get JSON API Request actions
 */
export function sendJsonApiRequest(request: Request): ApiRequestAction {
    request.headers.push({
        key: "Content-Type",
        value: "application/json"
    });
    request.headers.push({
        key: "Accept",
        value: "application/json"
    });

    return sendApiRequest(request);
}

export function receivedApiResponse(requestName: RequestNames, variables: any[], response: ResponseApiDTO) : ApiResponseAction {
    const data = 'data' in response ? response.data : response.details;

    return {
        type: API_RESPONSE,
        newSession: response.session,
        variables,
        response: {
            name: requestName,
            data,
        }
    }
}