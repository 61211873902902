import {State} from "./State";

export const ADD_STATE_FILTER_TABLE_FILTER_ACTION_NAME = "ADD_STATE_FILTER_TABLE_FILTER";
export type AddStateFilter_TableFilter_Types = {
    type: typeof ADD_STATE_FILTER_TABLE_FILTER_ACTION_NAME;
    filter: State[],
}

export const DELETE_STATE_FILTER_TABLE_FILTER_ACTION_NAME = "DELETE_STATE_FILTER_TABLE_FILTER";
export type DeleteStateFilter_tableFilter_Types = {
    type: typeof DELETE_STATE_FILTER_TABLE_FILTER_ACTION_NAME;
    state: State,
}

export const CHANGE_STATE_POPUP_ACTION_NAME = "CHANGE_STATE_POPUP";
export type ChangeStatePopup_Types = {
    type: typeof CHANGE_STATE_POPUP_ACTION_NAME;
    open: boolean,
}

export const TABLE_FILTER_CHANGE_SEARCH_FIELD_ACTION_NAME = "TABLE_FILTER_CHANGE_SEARCH_FIELD";
export type TableFilterChangeSearchField_Types = {
    type: typeof TABLE_FILTER_CHANGE_SEARCH_FIELD_ACTION_NAME;
    search: string,
}


export type TableFilterActionTypes =
    AddStateFilter_TableFilter_Types |
    DeleteStateFilter_tableFilter_Types |
    ChangeStatePopup_Types |
    TableFilterChangeSearchField_Types;