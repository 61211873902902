import {CLOSE_NEW_DIALOG_ACTION, NewDialogAction, OPEN_NEW_DIALOG_ACTION} from "../actions";
import {NewDialogState} from "../newDialogState";

const initState: NewDialogState = {
    open: false
};

function openDialog(oldState: NewDialogState, action: NewDialogAction) {
    return Object.assign({}, oldState, {
        open:true,
    });
}

function closeDialog(oldState: NewDialogState, action: NewDialogAction): NewDialogState {
    return Object.assign({}, oldState, {
        open:false,
    });
}

export function newDialog(state: NewDialogState = initState, action: NewDialogAction): NewDialogState {
    switch (action.type) {
        case OPEN_NEW_DIALOG_ACTION:
            return openDialog(state, action);

        case CLOSE_NEW_DIALOG_ACTION:
            return closeDialog(state, action);

        default:
            return state;
    }
}