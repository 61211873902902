import {FC, memo} from "react";
import React from "react";
import {useLocation} from "react-router-dom";
import {AppBar, Hidden, Toolbar} from "@material-ui/core";
import MobileHeader from "./Components/MobileHeader";
import DesktopHeader from "./Components/DesktopHeader";

export interface HeaderViewStates {
    loggedIn: boolean
}

export interface HeaderViewDispatches {

}

export type HeaderViewProps =
    HeaderViewStates
    & HeaderViewDispatches
    ;

export const HeaderView: FC<HeaderViewProps> = memo(({
                                                         loggedIn
                                                     }) => {
        const location = useLocation();
        return (
            <>
                <div style={{filter: loggedIn ? "" : "blur(3px)"}}>
                    <AppBar>
                        <Toolbar style={{minHeight: '48px'}}>
                                <Hidden smUp>
                                    <MobileHeader
                                        currentPage={loggedIn ? location.pathname : "/settings"}
                                    />
                                </Hidden>
                                <Hidden xsDown>
                                    <DesktopHeader
                                        currentPage={loggedIn ? location.pathname : "/settings"}
                                    />
                                </Hidden>
                        </Toolbar>
                    </AppBar>
                </div>
                <Toolbar style={{minHeight: '48px'}}/> {/* This is because the header is fixed */}
            </>
        );
    }
)