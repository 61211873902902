import MachineView, {MachineViewDispatches, MachineViewStates} from "./MachineView";
import {connect} from "react-redux";
import {getMachinesV2, getToken} from "../../data/machines/actions";
import {StoreState} from "../../StoreState";
import {AutoActivate_open} from "./AutoActivate/Actions";
import {ACTIVE, EXPIRED, OUT_OF_SERVICE} from "./Filter/State";
import {Machine, Token} from "../../data/machines/MachinesState";

export interface MachineProps {
}

const MachineStates: (state: StoreState, props: MachineProps) => MachineViewStates =
    (state, props) => {
    return {
            machines: state.machineCollection.machines.filter(MachineFilter(state)),
            tokens: state.machineCollection.tokens.filter(TokenFilter(state))
        };
    };

const MachineDispatches: (dispatch: any) => MachineViewDispatches = dispatch => {
    return {
        refresh: () => {
            dispatch(getMachinesV2());
            dispatch(getToken());
        },
        onAutoActivate: (key, token) => dispatch(AutoActivate_open(token, key))
    };
};

const MachineFilter = (state: StoreState) => {
    const activeFilter = state.tableFilter.states.includes(ACTIVE);
    const outOfServiceFilter = state.tableFilter.states.includes(OUT_OF_SERVICE);
    const search = state.tableFilter.searchField.toLowerCase();

    return (machine: Machine): boolean => {
        if (machine.enabled && !activeFilter) return false;
        if (!machine.enabled && !outOfServiceFilter) return false;

        if (search != "") {
            let found = false;
            if (machine.display_name.toLowerCase().includes(search)) found = true;
            if (machine.enabled && machine.terminal?.toLowerCase().includes(search)) found = true;
            if (machine.enabled && machine.terminal_serial?.toLowerCase().includes(search)) found = true;

            if (!found) return false;
        }

        return true;
    }
}

const TokenFilter = (state: StoreState) => {
    const activeFilter = state.tableFilter.states.includes(ACTIVE);
    const expired = state.tableFilter.states.includes(EXPIRED);
    const search = state.tableFilter.searchField.toLowerCase();

    return (token: Token): boolean => {
        if (token.expired && !expired) return false;
        if (!token.expired && !activeFilter) return false;

        if (search != "") {
            if (!token.machine.toLowerCase().includes(search)) return false;
        }

        return true;
    }
}

const Machines = connect(
    MachineStates,
    MachineDispatches
)(MachineView);


export default Machines;


// import React from 'react';
// import {withStyles} from '@material-ui/core/styles';
// import {Table, Paper, TableHead, TableRow, TableBody, TableCell, Box, Select, MenuItem} from '@material-ui/core';
// import {fetchMachines} from "../../managers/MachineManager";
// import OrganisationDialogController from "./OrganisationDialog/OrganisationDialogController";
// import {getOrganisations} from "../../managers/sessionManager";
// import {createStore} from "redux";
// import kaplockApp from "../../Stores/Reducers";
// import {connect, Provider} from "react-redux";
// import {newOrganisation} from "../../Stores/Actions";
// import GeneralSnackbar from "../../components/Snackbars/ErrorSnackbar/GeneralSnackbar";
//
// export const store = createStore(kaplockApp);
//
//
// const styles = {
//     root: {
//         width: '100%',
//         // marginTop: theme.spacing(3),
//         overflowX: 'auto',
//     },
//     table: {
//         minWidth: 650,
//     },
// };
//
// class Machines extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             machines: [],
//             locations: [],
//             organisations: getOrganisations(),
//             organisationDialogOpen: false,
//         };
//
//         this.locationFetched = 0;
//         this.machines = [];
//     }
//
//     componentDidMount() {
//         this.fetchLocations(this.props.locationSelect);
//     }
//
//     componentDidUpdate(prevProps, prevState, snapshot) {
//         if (prevProps.locationSelect !== this.props.locationSelect) {
//             this.fetchLocations(this.props.locationSelect);
//         }
//     }
//
//     fetchLocations() {
//         fetchMachines(this.props.locationSelect)
//             .then(data => {
//                 this.setState({
//                     locations: data[0],
//                     machines: data[1]
//                 });
//             })
//             .catch(() => {
//                 store.dispatch()
//             });
//     }
//
//     organisationSelectChanged(event) {
//         let selection = event.target.value;
//         if (selection === "-1" || selection === "-2") {
//             store.dispatch(newOrganisation());
//         }
//     }
//
//     render() {
//         const {classes} = this.props;
//         return (
//             <Provider store={store}>
//                 <Box m={1}>
//                     <Paper className={classes.root}>
//                         <Table className={classes.table}>
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell>Machine UUID</TableCell>
//                                     <TableCell align="right">Organistation</TableCell>
//                                     <TableCell align="right">Location (WIP)</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {this.state.machines.map(row => (
//                                     <TableRow key={row.name}>
//                                         <TableCell component="th" scope="row"
//                                                    style={{textDecorationLine: row.oos ? "line-through" : "none"}}>
//                                             {row.name}
//                                         </TableCell>
//                                         <TableCell align="right">
//                                             <Select value={row.organisation} disabled={row.oos}
//                                                     onChange={event => this.organisationSelectChanged(event)}>
//                                                 <MenuItem key="-1" value="-1">--New--</MenuItem>
//                                                 {
//                                                     Object.keys(this.state.organisations).map((uuid) => {
//                                                         return <MenuItem key={uuid} value={uuid}>
//                                                             {this.state.organisations[uuid]}
//                                                         </MenuItem>
//                                                     })
//                                                 }
//                                                 <MenuItem key="-2" value="-2">--New--</MenuItem>
//                                             </Select>
//                                         </TableCell>
//                                         <TableCell align="right">
//                                             <Select value={row.location} disabled={true}>
//                                                 {
//                                                     this.state.locations.map(location => {
//                                                         return <MenuItem value={location[0]}
//                                                                          key={location[0]}>{location[1]}</MenuItem>
//                                                     })
//                                                 }
//                                             </Select>
//                                         </TableCell>
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </Paper>
//                     <OrganisationDialogController/>
//                     <GeneralSnackbar type="error" />
//                     <GeneralSnackbar type="success" />
//                 </Box>
//             </Provider>
//         );
//     }
// }
//
// // export default withStyles(styles)(Machines);
