import React from 'react';
import routes from './routes.js';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';
import {
    createTheme,
    MuiThemeProvider,
    CssBaseline,
} from '@material-ui/core';
import 'typeface-roboto';
import './styles/App.css';
import {Provider} from "react-redux";
import {createAppStore} from "./Stores/createStore";
import {Notification} from "./notifications/components/Notification";
import {LoggedIn} from "./auth/components/LoggedIn";
import {Header} from "./components/Header/Controller";

const theme = createTheme({
    palette: {
        primary: {main: "#FFCD05"},
        background: {default: "#e0e0e0"},
    },
});

const store = createAppStore();

// class App extends React.Component {
const App = () => {
    return (
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <CssBaseline/>
                <Notification/>
                <Router>
                    <Header />
                    <div id="content_container">
                        <LoggedIn>
                            <Switch>
                                {routes.map(route => (
                                    <Route
                                        key={route.path}
                                        path={process.env.PUBLIC_URL + route.path}
                                        exact={route.exact}
                                        component={route.main}
                                    />
                                ))}
                            </Switch>
                        </LoggedIn>
                    </div>
                </Router>
            </Provider>
        </MuiThemeProvider>
    );
    // }
}

export default App;
