import {connect} from "react-redux";
import {PriceFieldView, PriceFieldViewDispatches, PriceFieldViewStates} from "./PriceFieldView";
import {StoreState} from "../../../StoreState";

export interface PriceFieldProps {
    name: string,
    value: string,
    label?: string,
    enabled?: boolean,
    onChange: (value: string) => void,
    onFinished?: (value: number) => void,
}

const PriceFieldStates: (state: StoreState, props: PriceFieldProps) => PriceFieldViewStates =
    (state, props) => {
        return {
            ...props,
            value: props.value.replace(".", ","),
            label: props.label ? props.label : props.name,
            enabled: props.enabled === undefined ? true : props.enabled,
        }
    };

const PriceFieldDispatches: (dispatch: any, props: PriceFieldProps) => PriceFieldViewDispatches =
    (dispatch, props) => {
        return {
            onChange: (event) => {
                props.onChange(event.target.value);
            },

            onFinished: (event) => {
                if (props.onFinished) {
                    const value = props.value.replace(",", ".");
                    const price = Math.round(Number(value) * 100);

                    props.onFinished(price);
                }
            }
        }
    };


export const PriceField = connect(
    PriceFieldStates,
    PriceFieldDispatches
)(PriceFieldView);