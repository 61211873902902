import {Token} from "./MachinesState";
import {MachineV2ResponseDTO, TokenDTO} from "../../api/ApiResponses";
import {
    MACHINE_EDIT_TOKEN_ACTION, MACHINE_EDITABLE_TOKEN_ACTION,
    MACHINE_FETCHED_ACTION, MACHINE_FETCHED_TOKEN_ACTION,
    MACHINE_GET_ACTION,
    MACHINE_GET_TOKEN_ACTION, MachineEditableTokenAction, MachineEditTokenAction,
    MachineFetchedAction, MachineFetchedTokenAction,
    MachineGetAction, MachineGetTokenAction
} from "./types";


export function getMachinesV2(): MachineGetAction {
    return {
        type: MACHINE_GET_ACTION,
    }
}

export function getToken(): MachineGetTokenAction {
    return {
        type: MACHINE_GET_TOKEN_ACTION,
    }
}

export function addMachinesV2(response: MachineV2ResponseDTO): MachineFetchedAction {
    return {
        type: MACHINE_FETCHED_ACTION,
        machines: response.data.map(({
                                         machine,
                                         terminal_serial,
                                         terminal,
                                         out_of_service,
                                         update_ready,
                                         alias
                                     }) => {
            return {
                uuid: machine,
                display_name: alias ? alias : machine,
                terminal,
                terminal_serial,
                enabled: !out_of_service,
                update_ready,
                video: null,
            }
        })
    }
}

export function addTokens(response: TokenDTO): MachineFetchedTokenAction {
    return {
        type: MACHINE_FETCHED_TOKEN_ACTION,
        tokens: response.data.map(({id, expire_at, created_at}) => {
            return {
                machine: id,
                token: "",
                expired: new Date() > new Date(expire_at),
                created_at: new Date(created_at),
                editable: true,
                saved: false,
            }
        })
    }
}

export function saveTokenChanges(token: Token): MachineEditTokenAction {
    return {
        type: MACHINE_EDIT_TOKEN_ACTION,
        token,
    }
}

export function setEditableToken(token: Token, editable: boolean, saved: boolean = false): MachineEditableTokenAction {
    token.editable = editable;
    token.saved = saved;
    return {
        type: MACHINE_EDITABLE_TOKEN_ACTION,
        token,
    }
}
