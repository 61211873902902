import {
    ADD_DIALOG_CHANGE_NAME_ACTION_NAME,
    ADD_DIALOG_CLOSE_ACTION_NAME,
    ADD_DIALOG_INVALIDATE_ACTION_NAME,
    ADD_DIALOG_OPEN_ACTION_NAME,
    ADD_DIALOG_SET_FILE_ACTION_NAME,
    AddDialogActionTypes,
    AddDialogChangeName_Types,
    AddDialogClose_Types,
    AddDialogInvalidate_Types,
    AddDialogSetFile_Types,
    UPLOAD_CHUNK_ACTION_NAME, UploadChunk_Types,
} from "./Types";
import {AddDialogState, FILE_TYPE_NONE, FILE_TYPE_UNSUPPORTED, FILE_TYPE_VIDEO, FileType} from "./State"

const initState: AddDialogState = {
    open: false,
    saving: false,
    name: "",
    nameError: "",
    file: undefined,
    fileType: FILE_TYPE_NONE,
    fileError: "",
    chunkPointer: -1,
    chunks: 0,
    fileUuid: "",
}

const FILE_ERROR_TEXT = "- videos: mp4"
export const CHUNK_SIZE = 1000 * 1000; // 1MB

function open(state: AddDialogState): AddDialogState {
    if (state.open) return state;

    return {
        ...state,
        open: true,
        saving: false,
        name: "",
        nameError: "",
        file: undefined,
        fileType: FILE_TYPE_NONE,
        fileError: "",
        chunkPointer: -1,
        chunks: 0,
        fileUuid: "",
    }
}

function changeName(state: AddDialogState, action: AddDialogChangeName_Types): AddDialogState {
    return {
        ...state,
        name: action.name,
        nameError: "",
    }
}

function invalidate(state: AddDialogState, action: AddDialogInvalidate_Types): AddDialogState {
    return {
        ...state,
        saving: false,
        nameError: action.fields.name ?
            "- Must have 3 character.\n" +
            "- Only a-z, A-Z, 0-9 and underscore (_) are allowed\n" +
            "- Starts with a-z or A-Z" : "",
        fileError: action.fields.file ? FILE_ERROR_TEXT : "",
    }
}

function close(state: AddDialogState, action: AddDialogClose_Types): AddDialogState {
    if (action.save) {
        return {
            ...state,
            saving: true,
        }
    }

    return {
        ...state,
        fileUuid: state.saving ? state.fileUuid : "",
        open: false,
    }
}

function setFile(state: AddDialogState, action: AddDialogSetFile_Types): AddDialogState {
    const file = action.file;
    const type = getFromSupportedFileType(file?.type || "");

    return {
        ...state,
        file: file,
        fileType: type,
        fileError: type === "Unsupported" ? FILE_ERROR_TEXT : "",
        chunks: file ? Math.ceil(file.size / CHUNK_SIZE) : 0,
        chunkPointer: 0,
    }
}

function setChunkPointer(state: AddDialogState, action: UploadChunk_Types): AddDialogState {
    return {
        ...state,
        chunkPointer: action.chunk,
        fileUuid: action.file,
    }
}

export function AddDialogReducer(state = initState, action: AddDialogActionTypes): AddDialogState {
    switch (action.type) {
        case ADD_DIALOG_OPEN_ACTION_NAME:
            return open(state);
        case ADD_DIALOG_CHANGE_NAME_ACTION_NAME:
            return changeName(state, action);
        case ADD_DIALOG_INVALIDATE_ACTION_NAME:
            return invalidate(state, action)
        case ADD_DIALOG_CLOSE_ACTION_NAME:
            return close(state, action);
        case ADD_DIALOG_SET_FILE_ACTION_NAME:
            return setFile(state, action);
        case UPLOAD_CHUNK_ACTION_NAME:
            return setChunkPointer(state, action);
        default:
            return state;
    }
}

function getFromSupportedFileType(type: string): FileType {
    switch (type) {
        case "video/mp4":
            return FILE_TYPE_VIDEO;
        default:
            return FILE_TYPE_UNSUPPORTED;
    }
}