import {
    DOWNLOAD_FILE,
    FILES_DOWNLOAD_ACTION_NAME,
    FilesActionTypes, FilesDownload_Types,
    GET_FILES,
    GET_FILES_ACTION_NAME,
    GetFiles_Types, REMOVE_FILE_ACTION_NAME, REMOVE_FILES, RemoveFile_Types
} from "./Types";
import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import {interval, merge, pipe} from "rxjs";
import {StoreState} from "../../StoreState";
import {auditTime, buffer, map, throttle} from "rxjs/operators";
import {sendJsonApiRequest} from "../../api/actions";
import config from "../../config";

const handlerGetFilesAction = (action$: ActionsObservable<FilesActionTypes>) => action$.pipe(
    ofType(GET_FILES_ACTION_NAME),
    pipe(
        map((action) => action as GetFiles_Types),
        throttle(() => interval(3000)),
        map(() => {
            return sendJsonApiRequest({
                name: GET_FILES,
                url: config.apiUrl + "/v2/file",
                method: "GET",
                headers: [],
                requireAuth: true,
                body: "",
            })
        })
    )
);

const handlerRemoveFile = (action$: ActionsObservable<FilesActionTypes>) => action$.pipe(
    ofType(REMOVE_FILE_ACTION_NAME),
    pipe(
        map((action) => action as RemoveFile_Types),
        buffer(action$
            .ofType(REMOVE_FILE_ACTION_NAME)
            .pipe(auditTime(2000))
        ),
        map((actions) => {
            const uuids = actions.map((action) => {
                return action.file.uuid;
            });

            return sendJsonApiRequest({
                name: REMOVE_FILES,
                url: config.apiUrl + "/v2/file",
                method: "DELETE",
                headers: [],
                requireAuth: true,
                body: JSON.stringify({
                    "data": uuids,
                }),
            })
        })
    )
);

const handlerDownloadFile = (action$: ActionsObservable<FilesActionTypes>) => action$.pipe(
    ofType(FILES_DOWNLOAD_ACTION_NAME),
    pipe(
        map((action) => action as FilesDownload_Types),
        map((action) => {
            return sendJsonApiRequest({
                name: DOWNLOAD_FILE,
                url: config.apiUrl + "/v2/file/" + action.file.uuid,
                method: "GET",
                headers: [],
                requireAuth: true,
                responseVariables: [action.file.name],
                body: "",
            })
        })
    )
);

export const FilesEpic = (action$: ActionsObservable<FilesActionTypes>, state$: StateObservable<StoreState>) => merge(
    handlerGetFilesAction(action$),
    handlerRemoveFile(action$),
    handlerDownloadFile(action$)
)