import React, {FC} from "react";
import {DefaultTextFieldProps, DefaultTextField} from "./DefaultTextField";

export type PasswordFieldProps = DefaultTextFieldProps;
export const PasswordField: FC<PasswordFieldProps> = ({children, ...others}) => {
    return (
        <DefaultTextField
            type="password"
            autoComplete="current-password"
            {...others}
        />
    );
};