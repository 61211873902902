import {
    ADD_DIALOG_CHANGE_NAME_ACTION_NAME,
    ADD_DIALOG_CLOSE_ACTION_NAME,
    ADD_DIALOG_INVALIDATE_ACTION_NAME,
    ADD_DIALOG_OPEN_ACTION_NAME,
    ADD_DIALOG_SET_FILE_ACTION_NAME,
    AddDialogChangeName_Types,
    AddDialogClose_Types,
    AddDialogInvalidate_Types,
    AddDialogOpen_Types,
    AddDialogSetFile_Types,
    UPLOAD_CHUNK_ACTION_NAME,
    UploadChunk_Types,
} from "./Types";

export function OpenAddDialog(): AddDialogOpen_Types {
    return {
        type: ADD_DIALOG_OPEN_ACTION_NAME,
    }
}

export function ChangeName(name: string): AddDialogChangeName_Types {
    return {
        type: ADD_DIALOG_CHANGE_NAME_ACTION_NAME,
        name
    }
}

export function CloseAddDialog(save: boolean): AddDialogClose_Types {
    return {
        type: ADD_DIALOG_CLOSE_ACTION_NAME,
        save,
    }
}

export function Invalidate(nameInvalid: boolean, filesInvalid: boolean): AddDialogInvalidate_Types {
    return {
        type: ADD_DIALOG_INVALIDATE_ACTION_NAME,
        fields: {
            name: nameInvalid,
            file: filesInvalid,
        }
    }
}

export function SetFile(file: File | undefined): AddDialogSetFile_Types {
    return {
        type: ADD_DIALOG_SET_FILE_ACTION_NAME,
        file,
    }
}

export function UploadNextChunk(file: string, chunk: number): UploadChunk_Types {
    return {
        type: UPLOAD_CHUNK_ACTION_NAME,
        file,
        chunk
    }
}
