import React, {ChangeEvent, FocusEvent, FC} from "react";
import {FormControl, Input, InputAdornment, InputLabel} from "@material-ui/core";

export interface PriceFieldViewStates {
    name: string,
    label: string,
    value: string,
    enabled: boolean,
}

export interface PriceFieldViewDispatches {
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
    onFinished: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
}

export type PriceFieldViewProps = PriceFieldViewStates & PriceFieldViewDispatches;

export const PriceFieldView: FC<PriceFieldViewProps> = ({
                                                            name,
                                                            value,
                                                            onChange,
                                                            onFinished,
                                                            label,
                                                            enabled
                                                        }) => {
    return (
        <FormControl fullWidth disabled={!enabled}>
            <InputLabel htmlFor="standard-adornment-amount">{label}</InputLabel>
            <Input
                name={name}
                type={"currency"}
                value={value}
                onChange={onChange}
                onBlur={onFinished}
                startAdornment={<InputAdornment position="start">€</InputAdornment>}
            />
        </FormControl>
    );
};