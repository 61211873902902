import {File} from "./State";

export const GET_FILES_ACTION_NAME = "[fil] GET_FILES";
export type GetFiles_Types = {
    type: typeof GET_FILES_ACTION_NAME;
}

export const SET_FILES_ACTION_NAME = "[fil] SET_FILES";
export type SET_FILES_Types = {
    type: typeof SET_FILES_ACTION_NAME;
    data: GetFilesDTO,
}

export const REMOVE_FILE_ACTION_NAME = "[fil] REMOVE_FILE";
export type RemoveFile_Types = {
    type: typeof REMOVE_FILE_ACTION_NAME;
    file: File,
}

export const FILES_REMOVED_CONFIRMATION_ACTION_NAME = "[fil] FILES_REMOVED_CONFIRMATION";
export type FilesRemovedConfirmation_Types = {
    type: typeof FILES_REMOVED_CONFIRMATION_ACTION_NAME;
}

export const FILES_DOWNLOAD_ACTION_NAME = "[file] FILES_DOWNLOAD";
export type FilesDownload_Types = {
    type: typeof FILES_DOWNLOAD_ACTION_NAME;
    file: File,
}


export type FilesActionTypes =
    GetFiles_Types |
    SET_FILES_Types |
    RemoveFile_Types |
    FilesRemovedConfirmation_Types |
    FilesDownload_Types;

export const GET_FILES = "GET_FILES";
export interface GetFilesDTO {
    name: typeof GET_FILES;
    data?: {
        uuid: string,
        name: string,
        type: string,
        usage: number,
    }[]
}

export const REMOVE_FILES = "REMOVE_FILES";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";