import {HIDE_SNACK, SHOW_SNACK} from "./Actions";

const initState = {
    error: {
        open: false,
        message: "unknown error"
    },
    success: {
        open: false,
        message: "unknown error"
    }
};

function showSnack(oldState, payload) {
    let copy = Object.assign({}, oldState);
    copy[payload.type] = Object.assign({}, copy[payload.type], {
        open: true,
        message: payload.message
    });

    return copy;
}

function hideSnack(oldState, type) {
    let copy = Object.assign({}, oldState);
    copy[type] = Object.assign({}, copy[type], {
        open: false,
    });

    return copy;
}

function snacks(state = initState, action) {
    switch (action.type) {
        case SHOW_SNACK:
            return showSnack(state, action.payload);

        case HIDE_SNACK:
            return hideSnack(state, action.payload);

        default:
            return state;
    }
}

export {snacks}