import {
    ADD_STATE_FILTER_TABLE_FILTER_ACTION_NAME,
    AddStateFilter_TableFilter_Types,
    CHANGE_STATE_POPUP_ACTION_NAME,
    ChangeStatePopup_Types,
    DELETE_STATE_FILTER_TABLE_FILTER_ACTION_NAME,
    DeleteStateFilter_tableFilter_Types, TABLE_FILTER_CHANGE_SEARCH_FIELD_ACTION_NAME,
    TableFilterActionTypes, TableFilterChangeSearchField_Types,
} from "./Types";
import {ACTIVE, STATES, TableFilterState} from "./State"

const initState: TableFilterState = {
    states: [ACTIVE],
    statePopupOpen: false,
    searchField: "",
}

function AddStateFilter(state: TableFilterState, action: AddStateFilter_TableFilter_Types): TableFilterState {
    const newState = {
        ...state,
        states: [
            ...state.states,
            ...action.filter
        ],
    };

    if (newState.states.length == STATES.length) newState.statePopupOpen = false;
    return newState;
}

function DeleteStateFilter(state: TableFilterState, action: DeleteStateFilter_tableFilter_Types): TableFilterState {
    return {
        ...state,
        states: state.states.filter((state) => state != action.state),
    }
}

function ChangeStatePopup(state: TableFilterState, action: ChangeStatePopup_Types): TableFilterState {
    return {
        ...state,
        statePopupOpen: action.open,
    }

}

function ChangeSearchField(state: TableFilterState, action: TableFilterChangeSearchField_Types): TableFilterState {
    return {
        ...state,
        searchField: action.search,
    }
}

export function TableFilterReducer(state = initState, action: TableFilterActionTypes): TableFilterState {
    switch (action.type) {
        case ADD_STATE_FILTER_TABLE_FILTER_ACTION_NAME:
            return AddStateFilter(state, action);
        case DELETE_STATE_FILTER_TABLE_FILTER_ACTION_NAME:
            return DeleteStateFilter(state, action);
        case CHANGE_STATE_POPUP_ACTION_NAME:
            return ChangeStatePopup(state, action);
        case TABLE_FILTER_CHANGE_SEARCH_FIELD_ACTION_NAME:
            return ChangeSearchField(state, action);
        default:
            return state;
    }
}