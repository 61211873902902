import {ActionsObservable, ofType} from "redux-observable";
import {AuthActions, AuthLogin} from "../actions";
import {merge, pipe} from "rxjs";
import {concatMap, map} from "rxjs/operators";
import {sendJsonApiRequest} from "../../api/actions";
import config from "../../config";

const handleUserLogin = (action$: ActionsObservable<AuthActions>) => action$.pipe(
    ofType('[auth] user tries to log in'),
    pipe(
        map((action) => action as AuthLogin),
        concatMap((async ({email, password}) => {
            return sendJsonApiRequest({
                name: "LOGIN",
                url: config.apiUrl + "/login",
                method: "POST",
                headers: [],
                requireAuth: false,
                body: JSON.stringify({email, password})
            });
        }))
    )
);

const authEpicBase = (action$: ActionsObservable<AuthActions>) => merge(
    handleUserLogin(action$)
);

export const authEpic = [authEpicBase];