import {connect} from "react-redux";
import {
    FullWidthTextFieldView,
    FullWidthTextFieldViewDispatches,
    FullWidthTextFieldViewStates
} from "./FullWidthTextFieldView";
import {StoreState} from "../../../StoreState";

export interface FullWidthTextFieldProps {
    label: string,
    name: string,
    onChange: (value: string) => void,
    value: string,
    error?: string,
    enabled?: boolean,

    onFinished?: (value: string) => void,
}

const FullWidthTextFieldStates: (state: StoreState, props: FullWidthTextFieldProps) => FullWidthTextFieldViewStates =
    (state, props) => {
        return {
            placeholder: props.label,
            name: props.name,
            value: props.value,
            error: props.error ? props.error !== "" : false,
            errorText: props.error || "",
            enabled: props.enabled === undefined ? true : props.enabled,
        }
    };

const FullWidthTextFieldDispatches: (dispatch: any, props: FullWidthTextFieldProps) => FullWidthTextFieldViewDispatches =
    (dispatch, props) => {
        return {
            onChange: event => {
                props.onChange(event.target.value);
            },
            onFinished: event => {
                if (props.onFinished) props.onFinished(props.value)
            }
        }
    };


export const FullWidthTextField = connect(
    FullWidthTextFieldStates,
    FullWidthTextFieldDispatches
)(FullWidthTextFieldView);
