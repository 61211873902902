import {FC, memo, useCallback, useState} from "react";
import React from "react";
import {
    Box,
    Chip, ClickAwayListener,
    Grid, IconButton, InputAdornment,
    MenuItem, MenuList,
    Paper, Popover, TextField, Input,
} from "@material-ui/core";
import {State} from "./State";
import {ArrowDropDown} from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';

export interface TableFilterViewStates {
    selectedStates: State[],
    states: State[],
    statePopupOpen: boolean,
    searchField: string,
}

export interface TableFilterViewDispatches {
    onStateAdded: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void,
    onStateDelete: (state: State) => void,
    changeStatePopup: (open: boolean) => void,
    onSearchChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
}

export type TableFilterViewProps =
    TableFilterViewStates
    & TableFilterViewDispatches
    ;

export const TableFilterView: FC<TableFilterViewProps> = memo(({
                                                                   selectedStates,
                                                                   states,
                                                                   statePopupOpen,
                                                                   searchField,
                                                                   onStateAdded,
                                                                   onStateDelete,
                                                                   changeStatePopup,
                                                                   onSearchChange
                                                               }) => {
        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

        const onStateClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            changeStatePopup(true);
        }, [setAnchorEl]);
        const onStateClose = useCallback((event: React.MouseEvent<Document>) => {
            changeStatePopup(false);
        }, []);
        const noStates = states.length == 0 && !statePopupOpen;

        return (
            <Box marginBottom={2}>
                <Paper>
                    <Box paddingY={1} paddingX={0.5}>
                        <Grid container>
                            <Grid item sm={2} xs={12}>
                                <Paper>
                                    <Box display={"inline"} flexWrap={"wrap"}>
                                        {selectedStates.map((state) => (
                                            <Chip
                                                label={state.text}
                                                onDelete={() => onStateDelete(state)}
                                                onClick={() => onStateDelete(state)}
                                            />
                                        ))}
                                        <Box hidden={noStates}
                                             display={noStates ? "none" : "inline"}
                                             style={{float: "right"}}
                                        >
                                            <IconButton
                                                size={"small"}
                                                onClick={onStateClick}
                                            >
                                                <ArrowDropDown/>
                                            </IconButton>

                                            <Popover
                                                open={statePopupOpen}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                anchorEl={anchorEl}
                                            >
                                                <ClickAwayListener onClickAway={onStateClose}>
                                                    <MenuList>
                                                        {states.map((state: State) => (
                                                            <MenuItem id={state.id} onClick={onStateAdded}>
                                                                {state.text}
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Popover>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item sm>
                            </Grid>

                            <Grid item sm={2} xs={12}>
                                <Input fullWidth
                                       value={searchField}
                                       onChange={onSearchChange}
                                       endAdornment={
                                           <InputAdornment position="end">
                                               <IconButton>
                                                   <SearchIcon/>
                                               </IconButton>
                                           </InputAdornment>
                                       }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        );
    }
)