import React, {FC, useCallback} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {useFetchedMachines} from "../../../data/machines/hooks";
import {Machine} from "../../../data/machines/MachinesState";
import {AutoHideTableCell} from "../MachineView";
import {useDispatch} from "react-redux";
import {ConfigDialogOpen} from "../ConfigDialog/Actions";
import UpdateIcon from '@material-ui/icons/Update';
import {Grid, Tooltip, Typography} from "@material-ui/core";

interface MachineListViewProps {
    machines: Machine[];
    onClickTemplate: (machine: Machine) => () => void;
}

export interface MachineListProps {
    machines: Machine[];
}

const MachineListView: FC<MachineListViewProps> = ({
                                                       machines,
                                                       onClickTemplate,
                                                   }) => {
    return (
        <TableBody>
            {machines.map((machine) => (
                <TableRow hover key={"machine_" + machine.uuid} onClick={onClickTemplate(machine)}>
                    <TableCell>
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography variant={"body2"} hidden={!machine.enabled}>
                                    {machine.display_name}
                                </Typography>

                                <Tooltip title="Out of service" hidden={machine.enabled}>
                                    <Typography variant={"body2"} hidden={machine.enabled} style={{
                                        textDecorationLine: "line-through",
                                        color: "rgba(0,0,0,0.5)",
                                    }}>
                                        {machine.display_name}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item hidden={!machine.update_ready}>
                                <Tooltip title="Configuration ready to pull">
                                    <UpdateIcon/>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell align="center">{machine.enabled ? machine.terminal || "-" : ""}</TableCell>
                    <AutoHideTableCell align="right">
                        {machine.enabled ? machine.terminal_serial || "-" : ""}
                    </AutoHideTableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};

export const MachineList: FC<MachineListProps> = ({machines}) => {
    const dispatch = useDispatch();
    const onClickTemplate = useCallback((machine: Machine) => {
        return () => {
            // if (machine.enabled) {
            dispatch(ConfigDialogOpen(machine));
            // }
        }
    }, [dispatch]);
    return (
        <MachineListView machines={machines} onClickTemplate={onClickTemplate}/>
    );
};