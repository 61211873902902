import {FC, memo, useEffect} from "react";
import React from "react";
import {FormControl, InputLabel, ListSubheader, MenuItem, Select} from "@material-ui/core";
import {File} from "../../../../data/files/State"

export interface FileSelectorViewStates {
    id: string,
    label: string,
    sortedFiles: Map<string, File[]>,
    files: File[],
    selected: File | null,
    empty: boolean,
}

export interface FileSelectorViewDispatches {
    getFiles: () => void,
    onSelectionChange: (value: string, files: File[]) => void,
}

export type FileSelectorViewProps =
    FileSelectorViewStates
    & FileSelectorViewDispatches
    ;

export const FileSelectorView: FC<FileSelectorViewProps> = memo(({
                                                                     id,
                                                                     label,
                                                                     sortedFiles,
                                                                     files,
                                                                     selected,
                                                                     empty,
                                                                     getFiles,
                                                                     onSelectionChange,
                                                                 }) => {
        useEffect(() => {
            getFiles();
        }, [getFiles]);

        /*
        <ListSubheader key={key}>{key}</ListSubheader>
         */

        return (
            <FormControl variant={"standard"} fullWidth>
                <InputLabel id={"file-selector-" + id}>{label}</InputLabel>
                <Select
                    labelId={"file-selector-" + id}
                    id={id}
                    label={label}
                    onChange={(e, c) => onSelectionChange(e.target.value as string, files)}
                    value={selected ? selected.uuid : ""}
                >
                    {Array.from(sortedFiles.keys()).map((key) => {
                        let childs = sortedFiles.get(key)?.map((file: File) => (
                            <MenuItem key={file.uuid} value={file.uuid}>{file.name}</MenuItem>
                        ));

                        return [
                            <ListSubheader key={key}>{key}</ListSubheader>,
                            ...childs || []
                        ];
                    })}
                    <ListSubheader hidden={!empty} key={"-"}>No files found</ListSubheader>
                </Select>
            </FormControl>
        );

    }
)