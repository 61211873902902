export const AUTO_ACTIVATE_OPEN_ACTION_NAME = "AUTO_ACTIVATE_OPEN";
export type AutoActivate_open_Types = {
    type: typeof AUTO_ACTIVATE_OPEN_ACTION_NAME;
    token: string,
    key: string,
}

export const AUTO_ACTIVATE_CANCEL_ACTION_NAME = "AUTO_ACTIVATE_CANCEL";
export type AutoActivate_cancel_Types = {
    type: typeof AUTO_ACTIVATE_CANCEL_ACTION_NAME;
}

export const AUTO_ACTIVATE_ACTIVATE_ACTION_NAME = "AUTO_ACTIVATE_ACTIVATE";
export type AutoActivate_activate_Types = {
    type: typeof AUTO_ACTIVATE_ACTIVATE_ACTION_NAME;
}


export type AutoActivateActionTypes =
    AutoActivate_open_Types |
    AutoActivate_cancel_Types |
    AutoActivate_activate_Types
;