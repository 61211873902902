import {TableFilterView, TableFilterViewDispatches, TableFilterViewStates} from "./View";
import {connect} from "react-redux";
import {StoreState} from "../../../StoreState";
import {AddStateFilter, ChangeSearchFieldTableFilter, ChangeStatePopup, DeleteStateFilter} from "./Actions";
import {ACTIVE, EXPIRED, OUT_OF_SERVICE, STATES} from "./State";


export interface TableFilterProps {
}

const TableFilterStates: (state: StoreState, props: TableFilterProps) => TableFilterViewStates =
    (state, props) => {
        const selectedStates = state.tableFilter.states;

        return {
            selectedStates,
            states: STATES
                .filter((state) => !selectedStates.includes(state)),
            statePopupOpen: state.tableFilter.statePopupOpen,
            searchField: state.tableFilter.searchField,
        }
    };

const TableFilterDispatches: (dispatch: any, props: TableFilterProps) => TableFilterViewDispatches =
    (dispatch, props) => {
        return {
            onStateAdded: event => {
                const search = (event.target as Element).id;
                const state = STATES.find((state) => state.id == search);
                if (state != undefined) dispatch(AddStateFilter([state]));
            },
            onStateDelete: state => {
                dispatch(DeleteStateFilter(state));
            },
            changeStatePopup: open => {
                dispatch(ChangeStatePopup(open));
            },
            onSearchChange: event => {
                dispatch(ChangeSearchFieldTableFilter(event.target.value));
            },
        }
    };

export const TableFilter = connect(
    TableFilterStates,
    TableFilterDispatches,
)(TableFilterView);