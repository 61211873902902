import {FileSelectorView, FileSelectorViewDispatches, FileSelectorViewStates} from "./View";
import {connect} from "react-redux";
import {StoreState} from "../../../../StoreState";
import {FileType} from "../../AddDialog/State";
import {File} from "../../../../data/files/State"
import {GetFiles} from "../../../../data/files/Actions";


export interface FileSelectorProps {
    id: string,
    label: string,
    value: string | null,
    filter?: FileType;
    onChange: (file: File | null) => void,
}

const FileSelectorStates: (state: StoreState, props: FileSelectorProps) => FileSelectorViewStates =
    (state, props) => {
        const filteredFiles = state.files.files
            .filter((file) => {
                return props.filter === undefined || file.type === props.filter;
            });

        return {
            label: props.label,
            id: props.id,
            files: filteredFiles,
            sortedFiles: filteredFiles
                .reduce((prev: Map<string, File[]>, curr: File) => {
                    if (!prev.has(curr.type)) {
                        prev.set(curr.type, []);
                    }

                    const container = prev.get(curr.type);
                    if (container) container.push(curr);

                    return prev;
                }, new Map()),
            selected: state.files.files.find((file) => file.uuid === props.value) || null,
            empty: filteredFiles.length === 0
        }
    };

const FileSelectorDispatches: (dispatch: any, props: FileSelectorProps) => FileSelectorViewDispatches =
    (dispatch, props) => {
        return {
            getFiles: () => dispatch(GetFiles()),
            onSelectionChange: (value, files) => {
                console.log("Changed");

                const file = files.find((file) => file.uuid === value);
                if (file) props.onChange(file);
                else props.onChange(null);
            }
        }
    };

export const FileSelector = connect(
    FileSelectorStates,
    FileSelectorDispatches,
)(FileSelectorView);