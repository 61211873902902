export const SHOW_SNACK = 'SHOW_SNACK';
export const HIDE_SNACK = 'HIDE_SNACK';
export const NEW_ORGANISATION = 'NEW_ORGANISATION';
export const SET_ORGANISATION_DATA = 'SET_ORGANISATION_DATA';
export const CLOSE_ORGANISATION_DIALOG = 'CLOSE_ORGANISATION_DIALOG';

export function showSnack(type, message) {
    return {
        type: SHOW_SNACK,
        payload: {
            type: type,
            message: message
        },
    }
}
export function hideSnack(type) {
    return {
        type: HIDE_SNACK,
        payload: type,
    }
}

export function newOrganisation() {
    return {
        type: NEW_ORGANISATION,
    }
}

export function setOrganisationData(name, value) {
    return {
        type: SET_ORGANISATION_DATA,
        payload: {
            name: name,
            value: value,
        }
    }
}

export function closeOrganisationDialog() {
    return {
        type: CLOSE_ORGANISATION_DIALOG,
    }
}