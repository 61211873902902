import React, {FC} from "react";
import {DefaultTextFieldProps, DefaultTextField} from "./DefaultTextField";

export type EmailFieldProps = DefaultTextFieldProps;
export const EmailField: FC<EmailFieldProps> = ({children, ...others}) => {
    return (
        <DefaultTextField
            type="email"
            autoComplete="email"
            {...others}
        />
    );
};