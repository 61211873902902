import React, {FC} from "react";
import {Machine, Token} from "../../../data/machines/MachinesState";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useFetchedTokens} from "../../../data/machines/hooks";
import TableHead from "@material-ui/core/TableHead";
import {Fade, InputAdornment, Paper, Table, TableContainer, TextField} from "@material-ui/core";
import {useMachineTokenChange} from "./hooks";
import {StyledTableCell} from "../MachineView";
import {Save} from "@material-ui/icons"

export interface TokenListProps {
    tokens: Token[];
}

const TokenEntry: FC<{ token: Token }> = ({token}) => {
    return (
        <>
            <TableCell>{token.machine} {token.expired ? "(expired)" : ""}</TableCell>
            <TableCell align="right">
                <div hidden={token.expired}>
                    <TextField
                        value={token.token}
                        disabled={!token.editable}
                        placeholder={"Activate code"}
                        size="small"
                        onChange={useMachineTokenChange(token)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Fade in={token.saved}>
                                        <Save htmlColor={"#277c27"}/>
                                    </Fade>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </TableCell>
        </>
    )
}

const TokenListView: FC<{ tokens: Token[] }> = ({tokens}) => {
    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Registration</StyledTableCell>
                            <StyledTableCell align="right">Activation code</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tokens.map((token) => (
                            <TableRow key={"token_" + token.machine} style={{
                                textDecorationLine: token.expired ? "line-through" : "none",
                                color: "rgba(0,0,0,0.5)"
                            }}>
                                <TokenEntry token={token}/>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <br/>
        </>
    );
};

export const TokenList: FC<TokenListProps> = ({tokens}) => {
    if (tokens.length > 0) return <TokenListView tokens={tokens}/>
    else return <></>;
};