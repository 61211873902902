import {ActionsObservable, ofType} from "redux-observable";
import {BATCH_CALL_ACTION, BatchActions, BatchCallAction} from "../actions";
import {map, mergeMap} from "rxjs/operators";
import {merge, of, pipe} from "rxjs";

const handleBatchCall = (action$: ActionsObservable<BatchActions>) => action$.pipe(
    ofType(BATCH_CALL_ACTION),
    map((action) => action as BatchCallAction),
    pipe(
        mergeMap(action => of(...action.actions))
    )
);

export const batchEpic = (action$: ActionsObservable<BatchActions>) => merge(
    handleBatchCall(action$),
);