export const CouldNotAuthErrorNum = 4;
export const CouldNotAuthErrorDescr = "Could't authenticate";

export const CredentialsIncorrectErrorNum = 8;
export const CredentialsIncorrectErrorDescr = "Username or/and password incorrect";

export interface BackendError {
    errorNumber: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15,
    description: string;
}

export const ServerConnectionErrorNum = -1;
export const ServerConnectionErrorDescr = "Server connection error";

export interface ServerConnectionError {
    errorNumber: -1;
    description: string;
}

export const WipErrorNum = -2;
export const WipErrorDescr = "Work In Progress";

export interface WipError {
    errorNumber: -2;
    description: string;
}

export const UnknownErrorNum = -3;

export interface UnknownError {
    errorNumber: number;
    description: "Unknown error";
}

export type Error = BackendError | ServerConnectionError | WipError | UnknownError;


export interface NotificationsState {
    error: Error | null,
    showError: boolean,
}
