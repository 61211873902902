import {
    FILES_DOWNLOAD_ACTION_NAME,
    FILES_REMOVED_CONFIRMATION_ACTION_NAME,
    FilesActionTypes, FilesDownload_Types,
    FilesRemovedConfirmation_Types,
    REMOVE_FILE_ACTION_NAME,
    RemoveFile_Types,
    SET_FILES_ACTION_NAME,
    SET_FILES_Types,
} from "./Types";
import {FilesState} from "./State"

const initState: FilesState = {
    files: [],
}

function setFiles(state: FilesState, action: SET_FILES_Types): FilesState {
    if (!action.data.data) {
        return {
            ...state,
            files: [],
        }
    }

    const deletedFiles = state.files
        .filter((file) => file.deleted)
        .map((file) => file.uuid);

    return {
        ...state,
        files: action.data.data.map((file) => {
            const deleted = deletedFiles.includes(file.uuid);
            return {
                type: file.type,
                name: file.name,
                uuid: file.uuid,
                usage: file.usage,
                deleted,
                downloading: false,
            }
        })
    }
}

function removeFile(state: FilesState, action: RemoveFile_Types): FilesState {
    return {
        ...state,
        files: state.files.map((file) => {
            if (file.uuid === action.file.uuid) return {
                ...file,
                deleted: true,
            }

            return file;
        }),
    }
}

function downloadFile(state: FilesState, action: FilesDownload_Types): FilesState {
    return {
        ...state,
        files: state.files.map((file) => {
            if (file.uuid === action.file.uuid) return {
                ...file,
                downloading: true,
            }

            return file;
        }),
    }
}

function confirmRemove(state: FilesState, action: FilesRemovedConfirmation_Types): FilesState {
    return {
        ...state,
        files: state.files.filter((file) => !file.deleted),
    }
}

export function FilesReducer(state = initState, action: FilesActionTypes): FilesState {
    switch (action.type) {
        case SET_FILES_ACTION_NAME:
            return setFiles(state, action);
        case REMOVE_FILE_ACTION_NAME:
            return removeFile(state, action);
        case FILES_REMOVED_CONFIRMATION_ACTION_NAME:
            return confirmRemove(state, action);
        case FILES_DOWNLOAD_ACTION_NAME:
            return downloadFile(state, action);
        default:
            return state;
    }
}