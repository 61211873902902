import React from 'react';
import {
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
    SwipeableDrawer,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import routes from '../../../routes.js';
import {getAuthPermissions} from '../../../auth/managers/sessionManager';
import {Menu as MenuIcon, ExitToApp as LogoutIcon} from '@material-ui/icons';
import 'typeface-roboto';
import {useLogout} from "../../../auth/hooks/Login";

const useRoutesMenu = (currentPage, setOpenDrawer) => {
    const authPermissions = getAuthPermissions();
    const authPermissionsParsed = authPermissions.split('|');
    let header;

    const items = routes.map((route, index) => {
        let allowed = route.requiredPermissions.reduce((acc, requiredPermission) => {
            return authPermissionsParsed.includes(requiredPermission) && acc;
        }, true);

        if (currentPage === route.path) {
            header = route.header;
        }

        if (allowed) {
            return (
                <ListItem
                    button
                    component={Link}
                    onClick={() => setOpenDrawer(false)}
                    to={process.env.PUBLIC_URL + route.path}
                    selected={currentPage === route.path}
                    key={route.path}
                >
                    <ListItemText primary={route.header}/>
                </ListItem>
            )
        } else {
            return null;
        }
    });

    return {
        header,
        items
    }
}

const MobileHeader = props => {
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const onLogout = useLogout("Pressed logout button", () => setOpenDrawer(false));
    const routesMenu = useRoutesMenu(props.currentPage, setOpenDrawer);

    return (
        <>
            <SwipeableDrawer
                open={openDrawer}
                onOpen={() => setOpenDrawer(true)}
                onClose={() => setOpenDrawer(false)}
                key="Drawer"
            >
                <div style={{width: 250}} id="list">
                    <List>
                        {routesMenu.items}
                        <ListItem button onClick={onLogout}>
                            <ListItemText primary="Logout"/>
                            <ListItemIcon>
                                <LogoutIcon/>
                            </ListItemIcon>
                        </ListItem>
                    </List>
                </div>
            </SwipeableDrawer>
            <IconButton
                style={{marginRight: "16px"}}
                onClick={() => setOpenDrawer(true)}
                key="Menubutton"
            >
                <MenuIcon/>
            </IconButton>
            <Typography variant="h6" noWrap key="Headertitle">
                {routesMenu.header}
            </Typography>
        </>
    )
        ;
}

// const MobileHeader = props => {
//     const [openDrawer, setOpenDrawer] = React.useState(false);
//     const authPermissions = getAuthPermissions();
//     var authPermissionsParsed = authPermissions.split('|');
//
//     const classes = useStyles();
//     return [
//         <Drawer
//             open={openDrawer}
//             onClose={() => setOpenDrawer(false)}
//             key="Drawer"
//         >
//             <div style={{width: 250}} id="list">
//                 {/*<List>*/}
//                 {/*    <Select value={props.selectedLocation} onChange={event => {props.changeLocation(event.target.value); setOpenDrawer(false)}} className={classes.select} classes={{selectMenu: classes.selectMenu}}>*/}
//                 {/*      {Object.keys(props.organisations).map((uuid) => {*/}
//                 {/*        const name = props.organisations[uuid];*/}
//                 {/*        return <MenuItem value={uuid} key={uuid}>{name}</MenuItem>*/}
//                 {/*      })}*/}
//                 {/*    </Select>*/}
//                 {/*  {routes.map((route, index) => {*/}
//                 {/*    return route.requiredPermissions.reduce((acc, requiredPermission) => {*/}
//                 {/*      return authPermissionsParsed.includes(requiredPermission) && acc;*/}
//                 {/*    }, true) ?*/}
//                 {/*    <ListItem button component={Link} to={process.env.PUBLIC_URL + route.path} selected={props.currentPage === route.path} key={route.path} onClick={() => setOpenDrawer(false)}>*/}
//                 {/*      <ListItemText primary={route.header} />*/}
//                 {/*    </ListItem>*/}
//                 {/*    :null;*/}
//                 {/*  })}*/}
//                 {/*  <ListItem button onClick={() => {logout().then(() => props.rerender())}}>*/}
//                 {/*    <ListItemIcon>*/}
//                 {/*      <LogoutIcon />*/}
//                 {/*    </ListItemIcon>*/}
//                 {/*    <ListItemText primary="Logout" />*/}
//                 {/*  </ListItem>*/}
//                 {/*</List>*/}
//             </div>
//         </Drawer>,
//         // <IconButton
//         //   style={{marginRight: "16px"}}
//         //   onClick={() => setOpenDrawer(true)}
//         //   key="Menubutton"
//         // >
//         //   <MenuIcon />
//         // </IconButton>,
//         // <Typography variant="h6" noWrap key="Headertitle">
//         //   Backoffice
//         // </Typography>
//     ]
// }

export default MobileHeader;
