import React from 'react';
import Permissions from './components/Permissions';
import Sidebar from './components/Sidebar';
import Machines from './pages/Machines';
import Prices from './pages/Prices';
import Statistics from './pages/Statistics';
import Settings from './pages/Settings';
import LifeLink from './pages/LifeLink';
import Downloads from './pages/Downloads';
import {FilesPage} from './pages/Files/Controller'
import { Redirect } from 'react-router-dom';

const routes = [
  {
    path: "/",
    exact: true,
    requiredPermissions: [Permissions.Never],
    header: "Dashboard",
    main: () => <Redirect to={process.env.PUBLIC_URL + '/machines'} />,
  },
  {
    path: "/statistics",
    requiredPermissions: [Permissions.Never],
    header: "Statistics",
    main: Statistics
  },
  {
    path: "/prices",
    header: "Prices",
    requiredPermissions: [Permissions.Never],
    main: Prices
  },
  {
    path: "/machines",
    header: "Machines",
    requiredPermissions: [Permissions.Machines],
    main: Machines
  },
  {
    path: "/files",
    header: "Files",
    requiredPermissions: [Permissions.Machines,],
    main: FilesPage,
  },
  {
    path: "/events",
    header: "Events",
    requiredPermissions: [Permissions.Never],
    main: () => <div><Sidebar><div>Events sidebar</div></Sidebar><h2>Events main</h2></div>
  },
  {
    path: "/lifelink",
    header: "LifeLink",
    requiredPermissions: [Permissions.Never],
    main: LifeLink
  },
  {
    path: "/downloads",
    header: "Downloads",
    requiredPermissions: [Permissions.Never],
    main: Downloads
  },
  {
    path: "/settings",
    header: "Settings",
    requiredPermissions: [],
    main: Settings
  },
];
export default routes;
