import React, {FC, useCallback} from "react";
import DefaultSnackbarView from "./DefaultSnackbarView";
import Alert from "./Alert";
import {useNotification} from "../hooks";
import {useDispatch} from "react-redux";
import {Action} from "redux";
import {confirmError} from "../actions";

const NotificationView: FC<{ open: boolean, message: string }> = ({open, message}) => {
    const dispatch = useDispatch();
    const onClose = useCallback(() => {
        dispatch(confirmError());
    }, [dispatch]);

    return (
        <DefaultSnackbarView open={open}>
            <Alert severity="error" onClick={onClose}>
                {message}
            </Alert>
        </DefaultSnackbarView>
    );
};

export const useDispatchAction = <TDeps extends any[], A extends Action>(actionCreator: (...deps: TDeps) => A, ...deps: TDeps) => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(actionCreator(...deps));
    }, [deps, dispatch, actionCreator]);
};

export const Notification: FC = () => {
    const {open, message} = useNotification();

    return <NotificationView open={open} message={message}/>
};