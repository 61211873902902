import React from 'react';
import {
  Snackbar,
} from '@material-ui/core';
import SnackbarContentWrapper from './SnackbarContentWrapper';

function SuccessSnackbar(props) {
  return(
    <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={props.open}
        autoHideDuration={6000}
        onClose={props.handleClose}
      >
        <SnackbarContentWrapper
          onClose={props.handleClose}
          variant="error"
          message={props.message}
        />
      </Snackbar>
  );
}

export default SuccessSnackbar;
