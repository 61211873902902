import React, {FC} from "react";
import {
    Box,
    Button, Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    Grid, Switch
} from "@material-ui/core";
import {FullWidthTextField} from "../../../components/Forms/FullWidthTextField/FullWidthTextField";
import {PriceField} from "../../../components/Forms/PriceField/PriceField";
import {Machine} from "../../../data/machines/MachinesState";
import {FileSelector} from "../../Files/Components/FileSelector/Controller";
import {FILE_TYPE_VIDEO} from "../../Files/AddDialog/State";
import {File} from "../../../data/files/State";

export interface ConfigDialogFormValues {
}

export interface ConfigDialogViewStates {
    open: boolean,
    minPrice: string,
    currentPrice: string,
    loading: boolean,
    machine: Machine | null,
    video: string | null,
    outOfService: boolean,
}

export interface ConfigDialogViewDispatches {
    onCurrentPriceChange: (value: string) => void,
    onCurrentPrice: (value: number) => void,
    onMinPriceChange: (value: string) => void,
    onMinPrice: (value: number) => void,
    onAlias: (value: string) => void,
    onSave: () => void,
    onClose: () => void,
    onVideoChange: (video: File | null) => void,
    onOutOfService: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
}

export type ConfigDialogViewProps =
    ConfigDialogViewStates
    & ConfigDialogViewDispatches
    ;

export const View: FC<ConfigDialogViewProps> = ({
                                                    open,
                                                    loading,
                                                    minPrice,
                                                    currentPrice,
                                                    machine,
                                                    video,
                                                    outOfService,
                                                    onCurrentPriceChange,
                                                    onCurrentPrice,
                                                    onMinPrice,
                                                    onMinPriceChange,
                                                    onAlias,
                                                    onClose,
                                                    onSave,
                                                    onVideoChange,
                                                    onOutOfService,
                                                }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit machine</DialogTitle>
            <DialogContent dividers>
                <Box m={1} hidden={loading}>
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={outOfService}
                                        onChange={onOutOfService}
                                        name="out-of-service"
                                        color="primary"
                                        size={"small"}
                                    />
                                }
                                label="Out of service"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PriceField name="min_price" label="Minimum price" value={minPrice}
                                        onChange={onMinPriceChange}
                                        onFinished={onMinPrice}
                                        enabled={!outOfService}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <PriceField name="current_price" label="Current price" value={currentPrice}
                                        onChange={onCurrentPriceChange}
                                        onFinished={onCurrentPrice}
                                        enabled={!outOfService}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FullWidthTextField label={"Name"}
                                                name={"alias"}
                                                value={machine?.display_name || ""}
                                                onChange={onAlias}
                                                enabled={!outOfService}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FileSelector filter={FILE_TYPE_VIDEO}
                                          value={video}
                                          onChange={onVideoChange}
                                          label={"Video"}
                                          id={"video"}
                            />
                        </Grid>
                        <Grid item sm={6}>
                        </Grid>
                    </Grid>
                </Box>
                <Box m={1} hidden={!loading}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center">

                        <Grid item xs={3}>
                            <CircularProgress color={"primary"}/>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSave} color={"primary"}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}