import {AutoActivateView, AutoActivateViewDispatches, AutoActivateViewStates} from "./View";
import {connect} from "react-redux";
import {StoreState} from "../../../StoreState";
import {AutoActivate_activate, AutoActivate_close} from "./Actions";


export interface AutoActivateProps {
}

const AutoActivateStates: (state: StoreState, props: AutoActivateProps) => AutoActivateViewStates =
    (state, props) => {
        return {
            open: state.autoActivate.open,
            token: state.autoActivate.token,
            machineKey: state.autoActivate.key,
            redirect: state.autoActivate.redirect,
        }
    };

const AutoActivateDispatches: (dispatch: any, props: AutoActivateProps) => AutoActivateViewDispatches =
    (dispatch, props) => {
        return {
            cancel: () => dispatch(AutoActivate_close()),
            activate: () => dispatch(AutoActivate_activate()),
        }
    };

export const AutoActivate = connect(
    AutoActivateStates,
    AutoActivateDispatches,
)(AutoActivateView);