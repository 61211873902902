import {
    FilesPageActionTypes,
} from "./Types";
import {FilesPageState} from "./State"

const initState: FilesPageState = {}


export function FilesPageReducer(state = initState, action: FilesPageActionTypes): FilesPageState {
    switch (action.type) {
        default:
            return state;

    }
}