import React, {FC} from "react";
import {TextField, TextFieldProps} from "@material-ui/core";

export type DefaultTextFieldProps = TextFieldProps & { name: string };
export const DefaultTextField: FC<DefaultTextFieldProps> = ({name, children, ...others}) => {
    return (
        <TextField
            variant="outlined"
            margin="dense"
            id={name}
            name={name}
            fullWidth
            {...others}>
            {children}
        </TextField>
    );
};