import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import {interval, merge, pipe} from "rxjs";
import {debounceTime, filter, map, mergeMap, throttle} from "rxjs/operators";
import {sendJsonApiRequest} from "../../api/actions";
import {GET_MACHINES_V2, GET_TOKENS, SET_MACHINE_TOKEN} from "../../api/ApiResponses";
import config from "../../config";
import {StoreState} from "../../StoreState";
import {
    MACHINE_EDIT_TOKEN_ACTION,
    MACHINE_GET_ACTION,
    MACHINE_GET_TOKEN_ACTION, MachineActions,
    MachineEditTokenAction,
    MachineGetAction, MachineGetTokenAction
} from "./types";
import {setEditableToken} from "./actions";

const handlerMachineGetAction = (action$: ActionsObservable<MachineActions>) => action$.pipe(
    ofType(MACHINE_GET_ACTION),
    pipe(
        map((action) => action as MachineGetAction),
        throttle(() => interval(3000)),
        map(() => {
            return sendJsonApiRequest({
                name: GET_MACHINES_V2,
                url: config.apiUrl + "/v2/machine",
                method: "GET",
                headers: [],
                requireAuth: true,
                body: "",
            })
        })
    )
);

const handlerMachineGetTokenAction = (action$: ActionsObservable<MachineActions>) => action$.pipe(
    ofType(MACHINE_GET_TOKEN_ACTION),
    pipe(
        map((action) => action as MachineGetTokenAction),
        throttle(() => interval(3000)),
        map(() => {
            return sendJsonApiRequest({
                name: GET_TOKENS,
                url: config.apiUrl + "/v2/token",
                method: "GET",
                headers: [],
                requireAuth: true,
                body: "",
            });
        }),
    )
);

const handlerMachineEditTokenAction = (action$: ActionsObservable<MachineActions>) => action$.pipe(
    ofType(MACHINE_EDIT_TOKEN_ACTION),
    pipe(
        map((action) => action as MachineEditTokenAction),
        debounceTime(1500),
        filter((action) => action.token.token.length >= 6),
        mergeMap((action) => [
            setEditableToken(action.token, false),
            sendJsonApiRequest({
                name: SET_MACHINE_TOKEN,
                url: config.apiUrl + "/v2/token",
                method: "POST",
                headers: [],
                requireAuth: true,
                responseVariables: [action.token],
                body: JSON.stringify({
                    data: {
                        id: action.token.machine,
                        value: action.token.token,
                    },
                }),
            })
        ])
    )
);

export const machineEpic = (action$: ActionsObservable<MachineActions>, state$: StateObservable<StoreState>) => merge(
    handlerMachineGetAction(action$),
    handlerMachineGetTokenAction(action$),
    handlerMachineEditTokenAction(action$)
)