import React, {useState} from 'react';
import {getJson} from '../../managers/apiManager';
import {
  makeStyles,
  Box,
  Paper,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  hidden: {
    display: "none",
  },
  loaderScreen: {
    position: "absolute",
    width: "100%",
    height: "calc(100% - 48px)",
    top: "48px",
    left: "0px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  centerSpinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }
}));

const Downloads = props => {
  const [url, setUrl] = useState("");
  const [downloadActive, setDownloadActive] = useState(false);

  function download() {
    setDownloadActive(true);
    getJson("/downloads/transactions")
    .then(response => {
      setUrl(response.details);
      setTimeout(() => setDownloadActive(false), 1000);
    });
  }

  const classes = useStyles();
  return (
    <Box m={1}>
      <Paper>
        <List subheader={<ListSubheader>Downloads</ListSubheader>} disablePadding={true}>
          <Divider />
          <ListItem button disabled={downloadActive} onClick={download}>
            <ListItemText primary="Statistics" />
          </ListItem>
        </List>
      </Paper>
      {downloadActive ?
        <div className={classes.loaderScreen}>
          <div className={classes.centerSpinner}>
            <CircularProgress />
          </div>
        </div>
      : null}
      <iframe
        src={url}
        className={classes.hidden}
        title="download"
      />
    </Box>
  )
};

export default Downloads;
