import {Request, RequestNames, ResponseApiDTO} from "../ApiState"
import {API_RESPONSE, receivedApiResponse} from "../actions";
import {ErrorFired, onError, onServerError, onUnknownError,} from "../../notifications/actions";
import {ServerConnectionErrorDescr, ServerConnectionErrorNum,} from "../../notifications/NotificationsState";
import {StoreState} from "../../StoreState";

export const convertHeaders = (request: Request) => {
    const headers: HeadersInit = new Headers();
    request.headers.forEach(({key, value}) => {
        headers.set(key, value);
    });

    return headers;
};

export const addAuthHeaders = (request: Request, state: StoreState): Request => {
    if (!request.requireAuth) return request;

    request.headers.push({
        key: "session-id",
        value: state.auth.session,
    });

    return request;
};

export const convertFetchRequest = (request: Request): RequestInit => {
    return {
        method: request.method,
        headers: convertHeaders(request),
        body: request.method === "GET" ? undefined : request.body,
    }
};

function extractErrorActionFromResponse(response: ResponseApiDTO): ErrorFired {
    if (response.success)
        return onError({errorNumber: ServerConnectionErrorNum, description: ServerConnectionErrorDescr,});

    return onServerError({error: response.details});
}

async function processJson(requestName: RequestNames, variables: any[], response: Response) {
    let responseData: ResponseApiDTO;

    switch (response.status) {
        case 200:
            responseData = await response.json();
            return receivedApiResponse(requestName, variables, responseData);

        case 400:
            responseData = await response.json();
            return extractErrorActionFromResponse(responseData);

        case 500:
            return onError({errorNumber: ServerConnectionErrorNum, description: ServerConnectionErrorDescr,});

        default:
            return onUnknownError();
    }
}

async function processFile(contentType: string, requestName: RequestNames, variables: any[], response: Response) {
    const blob = await response.blob();
    const fileName = variables.length > 0 ? variables[0] : "file";
    let element = document.getElementById("direct_download");
    if (element == null) {
        element = document.createElement('a');
        element.id = "direct_download";
    }

    const link = element as HTMLLinkElement;
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    link.click();

    return {
        type: API_RESPONSE,
        variables,
        response: {
            name: requestName,
        }
    }
}

export const processResponseCode = async (requestName: RequestNames, variables: any[], response: Response) => {
    const contentType = response.headers.get("Content-Type")

    switch (contentType) {
        case "application/json":
            return processJson(requestName, variables, response);
        case "video/mp4":
            return processFile(contentType, requestName, variables, response);
        default :
            return onUnknownError();
    }

};