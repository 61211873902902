import React, {FC, memo} from "react";
import {Box, IconButton, TableBody, TableCell, TableRow, Tooltip} from "@material-ui/core";
import {File} from "../../../data/files/State"
import {AutoHideTableCell} from "../../Machines/MachineView";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from '@material-ui/icons/GetApp';

export interface FilesTablesDataProps {
    files: File[],
    onRemove: (file: File) => void,
    onDownload: (file: File) => void,
}

export const FilesTableData: FC<FilesTablesDataProps> = memo(
    ({files, onRemove, onDownload}) => {
        return (
            <TableBody>
                {files
                    .filter((file: File) => !file.deleted)
                    .map((file: File) => {
                            const tooltipText = "File is in usage (" + file.usage + "x)"
                            return (
                                <TableRow key={file.uuid}>
                                    <TableCell>{file.name}</TableCell>
                                    <AutoHideTableCell>{file.type}</AutoHideTableCell>
                                    <TableCell align="right">
                                        <Box display="inline">
                                            <Tooltip title={file.usage > 0 ? tooltipText : "Delete file"}>
                                                <Box display="inline">
                                                    <IconButton
                                                        aria-label="delete"
                                                        disabled={file.usage > 0}
                                                        size={"small"}
                                                        onClick={() => onRemove(file)}
                                                    >
                                                        <DeleteIcon fontSize="small"/>
                                                    </IconButton>
                                                </Box>
                                            </Tooltip>
                                            <Tooltip title={"Download file"}>
                                                <IconButton
                                                    aria-label="download"
                                                    size={"small"}
                                                    disabled={file.downloading}
                                                    onClick={() => onDownload(file)}
                                                >
                                                    <GetAppIcon fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    )}
            </TableBody>
        );
    }
)