import {AddDialogView, AddDialogViewDispatches, AddDialogViewStates} from "./View";
import {connect} from "react-redux";
import {StoreState} from "../../../StoreState";
import {ChangeName, CloseAddDialog, SetFile} from "./Actions";


export interface AddDialogProps {
}

const AddDialogStates: (state: StoreState, props: AddDialogProps) => AddDialogViewStates =
    (state, props) => {
        return {
            open: state.filesAdd.open,
            name: state.filesAdd.name,
            nameError: state.filesAdd.nameError,
            fileType: state.filesAdd.fileType,
            fileErrorText: state.filesAdd.fileError,
            fileHasError: state.filesAdd.fileError !== "",
            saving: state.filesAdd.saving,
            progress: state.filesAdd.chunkPointer / state.filesAdd.chunks * 100,
        }
    };

const AddDialogDispatches: (dispatch: any, props: AddDialogProps) => AddDialogViewDispatches =
    (dispatch, props) => {
        return {
            onNameChange: value => dispatch(ChangeName(value)),
            onClose: () => dispatch(CloseAddDialog(false)),
            onSave: () => dispatch(CloseAddDialog(true)),
            onFileSelect: (event) => OnFileSelect(event.target, dispatch)
        }
    };

function OnFileSelect(element: HTMLInputElement, dispatch: (event: any) => void) {
    if (!element.files || element.files.length === 0) {
        dispatch(SetFile(undefined))
        return;
    }

    dispatch(SetFile(element.files[0]));
}

export const AddDialog = connect(
    AddDialogStates,
    AddDialogDispatches,
)(AddDialogView);