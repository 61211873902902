export interface OpenNewDialogAction {
    type: "[new dialog] Open";
}
export const OPEN_NEW_DIALOG_ACTION = "[new dialog] Open";

export interface CloseNewDialogAction {
    type: "[new dialog] Close";
}
export const CLOSE_NEW_DIALOG_ACTION = "[new dialog] Close";

export type NewDialogAction = OpenNewDialogAction | CloseNewDialogAction;

export function openNewDialog(): OpenNewDialogAction {
    return {
        type: "[new dialog] Open"
    }
}
export function closeNewDialog(): CloseNewDialogAction {
    return {
        type: "[new dialog] Close"
    }
}