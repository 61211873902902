import {
    AUTO_ACTIVATE_ACTIVATE_ACTION_NAME,
    AUTO_ACTIVATE_CANCEL_ACTION_NAME,
    AUTO_ACTIVATE_OPEN_ACTION_NAME,
    AutoActivate_activate_Types,
    AutoActivate_cancel_Types,
    AutoActivate_open_Types,
} from "./Types";

export function AutoActivate_open(token: string, key: string): AutoActivate_open_Types {
    return {
        type: AUTO_ACTIVATE_OPEN_ACTION_NAME,
        token: token,
        key: key,
    }
}

export function AutoActivate_close(): AutoActivate_cancel_Types {
    return {
        type: AUTO_ACTIVATE_CANCEL_ACTION_NAME,
    }
}

export function AutoActivate_activate(): AutoActivate_activate_Types {
    return {
        type: AUTO_ACTIVATE_ACTIVATE_ACTION_NAME,
    }
}