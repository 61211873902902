import {
    CONFIG_DIALOG_DATA_ACTION_NAME,
    CONFIG_DIALOG_DATA_Types,
    CONFIG_DIALOG_EDIT_ALIAS_ACTION_NAME,
    CONFIG_DIALOG_EDIT_VIDEO_ACTION_NAME,
    CONFIG_DIALOG_ON_CLOSE_ACTION_NAME, CONFIG_DIALOG_ON_OUT_OF_SERVICE_ACTION_NAME,
    CONFIG_DIALOG_ON_PRICE_INPUT_ACTION_NAME,
    CONFIG_DIALOG_ON_PRICE_SET_ACTION_NAME,
    CONFIG_DIALOG_OPEN_ACTION_NAME,
    ConfigDialogEditAlias_Types,
    ConfigDialogEditVideo_Types,
    ConfigDialogOnClose_Types, ConfigDialogOnOutOfService_Types,
    ConfigDialogOnPriceInput_Types,
    ConfigDialogOnPriceSet_Types,
    ConfigDialogOpen_Types,
    GetMachineConfigDTO,
    PriceTypes
} from "./Types";
import {Machine} from "../../../data/machines/MachinesState";
import {File} from "../../../data/files/State";

export function ConfigDialogCurrentPriceChange(value: string): ConfigDialogOnPriceInput_Types {
    return {
        type: CONFIG_DIALOG_ON_PRICE_INPUT_ACTION_NAME,
        value,
        priceType: PriceTypes.current,
    }
}

export function ConfigDialogCurrentPriceSet(value: number): ConfigDialogOnPriceSet_Types {
    return {
        type: CONFIG_DIALOG_ON_PRICE_SET_ACTION_NAME,
        value,
        priceType: PriceTypes.current,
    }
}

export function ConfigDialogMinPriceChange(value: string): ConfigDialogOnPriceInput_Types {
    return {
        type: CONFIG_DIALOG_ON_PRICE_INPUT_ACTION_NAME,
        value,
        priceType: PriceTypes.min,
    }
}

export function ConfigDialogMinPriceSet(value: number): ConfigDialogOnPriceSet_Types {
    return {
        type: CONFIG_DIALOG_ON_PRICE_SET_ACTION_NAME,
        value,
        priceType: PriceTypes.min,
    }
}

export function ConfigDialogClose(save: boolean): ConfigDialogOnClose_Types {
    return {
        type: CONFIG_DIALOG_ON_CLOSE_ACTION_NAME,
        save,
    }
}

export function ConfigDialogOpen(machine: Machine): ConfigDialogOpen_Types {
    return {
        type: CONFIG_DIALOG_OPEN_ACTION_NAME,
        machine,
    }
}

export function ConfigDialogReceiveData(data: GetMachineConfigDTO): CONFIG_DIALOG_DATA_Types {
    return {
        type: CONFIG_DIALOG_DATA_ACTION_NAME,
        data,
    }
}

export function ConfigDialogEditAlias(alias: string): ConfigDialogEditAlias_Types {
    return {
        type: CONFIG_DIALOG_EDIT_ALIAS_ACTION_NAME,
        alias,
    }
}

export function ConfigDialogEditVideo(video: File | null): ConfigDialogEditVideo_Types {
    return {
        type: CONFIG_DIALOG_EDIT_VIDEO_ACTION_NAME,
        video: video,
    }
}

export function ConfigDialogSetOutOfService(value: boolean): ConfigDialogOnOutOfService_Types {
    return {
        type: CONFIG_DIALOG_ON_OUT_OF_SERVICE_ACTION_NAME,
        outOfService: value,
    }
}