import {AUTO_ACTIVATE_ACTIVATE_ACTION_NAME, AutoActivate_activate_Types, AutoActivateActionTypes} from "./Types";
import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import {merge, pipe} from "rxjs";
import {StoreState} from "../../../StoreState";
import {AddDialogActionTypes} from "../../Files/AddDialog/Types";
import {concatMap, map, mergeMap} from "rxjs/operators";
import {saveTokenChanges} from "../../../data/machines/actions";
import {AutoActivate_close} from "./Actions";

const handlerUploadChunkAction = (action$: ActionsObservable<AutoActivateActionTypes>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(AUTO_ACTIVATE_ACTIVATE_ACTION_NAME),
    pipe(
        map((action) => action as AutoActivate_activate_Types),
        mergeMap(() => {
            return [
                saveTokenChanges({
                    machine: state$.value.autoActivate.token,
                    token: state$.value.autoActivate.key,
                    created_at: new Date(),
                    editable: false,
                    expired: false,
                    saved: false,
                }),
                AutoActivate_close(),
            ]
        }),
    )
);

export const AutoActivateEpic = (action$: ActionsObservable<AutoActivateActionTypes>, state$: StateObservable<StoreState>) => merge(
    handlerUploadChunkAction(action$, state$)
)