import {applyMiddleware, combineReducers, createStore, Store} from "redux";
import {reducers} from "./Reducers";
import {combineEpics, createEpicMiddleware} from "redux-observable";
import {epics} from "./Epics";
import {composeWithDevTools} from "redux-devtools-extension"
import {StoreState} from "../StoreState";

export function createAppStore (): Store<StoreState> {
    const rootEpic = combineEpics(
        ...epics
    );
    const epicMiddleware = createEpicMiddleware();
    const rootReducer = combineReducers(reducers);

    const store = createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(epicMiddleware) as any) as any
    );

    epicMiddleware.run(rootEpic as any);
    return store as any;
}

