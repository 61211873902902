import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

const DefaultSnackbarView = ({open, children}) => {
    return (
        <Snackbar open={open} anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
                  onClose={() => {
                  }}>
            {children}
        </Snackbar>
    );
};

export default DefaultSnackbarView;