import {
    CONFIG_DIALOG_ON_CLOSE_ACTION_NAME,
    CONFIG_DIALOG_OPEN_ACTION_NAME,
    ConfigDialogActions, ConfigDialogOnClose_Types,
    ConfigDialogOpen_Types, GET_MACHINE_CONFIG, SetMachineConfigDTO, SetMachineSettingsDTO
} from "./Types";
import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import {StoreState} from "../../../StoreState";
import {merge, pipe} from "rxjs";
import {filter, map, mergeMap} from "rxjs/operators";
import {sendJsonApiRequest} from "../../../api/actions";
import {SAVE_MACHINE_CONFIG} from "../../../api/ApiResponses";
import config from "../../../config";

const handlerConfigDialogOpenAction = (action$: ActionsObservable<ConfigDialogActions>) => action$.pipe(
    ofType(CONFIG_DIALOG_OPEN_ACTION_NAME),
    pipe(
        map((action) => action as ConfigDialogOpen_Types),
        map((action) => {
            return sendJsonApiRequest({
                name: GET_MACHINE_CONFIG,
                url: config.apiUrl + "/v2/config/" + action.machine.uuid,
                method: "GET",
                headers: [],
                requireAuth: true,
                body: "",
            });
        }),
    ),
);

const handlerConfigDialogSaveAction = (action$: ActionsObservable<ConfigDialogActions>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(CONFIG_DIALOG_ON_CLOSE_ACTION_NAME),
    pipe(
        map((action) => action as ConfigDialogOnClose_Types),
        filter((action) => action.save),
        filter((action) => state$.value.configDialog.machine != null),
        mergeMap(() => {
            const requests = [sendConfigSaveRequest(state$.value)];

            if (state$.value.configDialog.outOfService != !state$.value.configDialog.machine?.enabled) {
                requests.push(sendSettingsSaveRequest(state$.value));
            }

            return requests;
        }),
    ),
);

function sendConfigSaveRequest(state: StoreState) {
    let data: SetMachineConfigDTO = {
        "min-pricing": state.configDialog.minPrice,
        "current-pricing": state.configDialog.currentPrice,
    };

    if (state.configDialog.machine?.display_name !== "" &&
        state.configDialog.machine?.display_name !== state.configDialog.machine?.uuid
    ) {
        data["alias"] = state.configDialog.machine?.display_name;
    }

    if (state.configDialog.machine?.video) {
        data["video"] = state.configDialog.machine?.video;
    }

    return sendJsonApiRequest({
        name: SAVE_MACHINE_CONFIG,
        url: config.apiUrl + "/v2/config/" + state.configDialog.machine?.uuid,
        method: "PUT",
        headers: [],
        requireAuth: true,
        body: JSON.stringify({
            data,
        }),
    });
}

function sendSettingsSaveRequest(state: StoreState) {
    const data: SetMachineSettingsDTO = {
        "out-of-service": state.configDialog.outOfService,
    };

    return sendJsonApiRequest({
        name: SAVE_MACHINE_CONFIG,
        url: config.apiUrl + "/v2/machine/" + state.configDialog.machine?.uuid + "/settings",
        method: "POST",
        headers: [],
        requireAuth: true,
        body: JSON.stringify({
            data,
        }),
    });
}

export const ConfigDialogEpic = (action$: ActionsObservable<ConfigDialogActions>, state$: StateObservable<StoreState>) => merge(
    handlerConfigDialogOpenAction(action$),
    handlerConfigDialogSaveAction(action$, state$),
)