import {InitUploadDTO} from "../pages/Files/AddDialog/Types";

export interface LoginResponseDTO {
    name: "LOGIN";
    data: {
        email: string;
        permissions: string;
    };
}

export interface OrganisationResponseDTO {
    name: "GET_ORGANISATIONS";
    data: Record<string, string>;
}

export interface LocationResponseDTO {
    name: "GET_LOCATIONS";
    data: Record<string, string>;
}

export interface MachineResponseDTO {
    name: "GET_MACHINES";
    data: {
        uuid: string;
        outOfService: boolean;
    }[];
}

export const GET_MACHINES_V2 = "GET_MACHINES_V2";
export interface MachineV2ResponseDTO {
    name: typeof GET_MACHINES_V2;
    data: {
        machine: string;
        terminal: string | null;
        terminal_serial: string | null;
        out_of_service: boolean;
        update_ready: boolean;
        alias?: string;
    }[];
}

export const GET_TOKENS = "GET_TOKENS";
export interface TokenDTO {
    name: typeof GET_TOKENS;
    data: {
        id: string;
        created_at: string;
        expire_at: string;
    }[];
}

export const SET_MACHINE_TOKEN = "SET_MACHINE_TOKEN";
export interface SetMachineTokenDTO {
    name: typeof SET_MACHINE_TOKEN;
}

export interface NewOrganisationResponseDTO {
    name: "CREATE_ORGANISATION";
    data: [string];
}

export const SAVE_MACHINE_CONFIG = "SAVE_MACHINE_CONFIG";

export interface UnknownResponseDTO {
    name: any;
    data: any;
}

export type Response =
    LoginResponseDTO |
    OrganisationResponseDTO |
    MachineV2ResponseDTO |
    LocationResponseDTO |
    UnknownResponseDTO |
    NewOrganisationResponseDTO |
    SetMachineTokenDTO |
    TokenDTO |
    InitUploadDTO;