export const ADD_DIALOG_OPEN_ACTION_NAME = "ADD_DIALOG_OPEN";
export type AddDialogOpen_Types = {
    type: typeof ADD_DIALOG_OPEN_ACTION_NAME;
}

export const ADD_DIALOG_CHANGE_NAME_ACTION_NAME = "ADD_DIALOG_CHANGE_NAME";
export type AddDialogChangeName_Types = {
    type: typeof ADD_DIALOG_CHANGE_NAME_ACTION_NAME;
    name: string,
}

export const ADD_DIALOG_CLOSE_ACTION_NAME = "ADD_DIALOG_CLOSE";
export type AddDialogClose_Types = {
    type: typeof ADD_DIALOG_CLOSE_ACTION_NAME;
    save: boolean,
}

export const UPLOAD_CHUNK_ACTION_NAME = "UPLOAD_CHUNK";
export type UploadChunk_Types = {
    type: typeof UPLOAD_CHUNK_ACTION_NAME;
    file: string;
    chunk: number;
}

export const ADD_DIALOG_INVALIDATE_ACTION_NAME = "ADD_DIALOG_INVALIDATE";
export type AddDialogInvalidate_Types = {
    type: typeof ADD_DIALOG_INVALIDATE_ACTION_NAME;
    fields: {
        name: boolean,
        file: boolean,
    }
}

export const ADD_DIALOG_SET_FILE_ACTION_NAME = "ADD_DIALOG_SET_FILE";
export type AddDialogSetFile_Types = {
    type: typeof ADD_DIALOG_SET_FILE_ACTION_NAME;
    file: File | undefined,
}


export type AddDialogActionTypes =
    AddDialogOpen_Types |
    AddDialogChangeName_Types |
    AddDialogClose_Types |
    AddDialogInvalidate_Types |
    AddDialogSetFile_Types |
    UploadChunk_Types;


export const UPLOAD_CHUNK = "UPLOAD_CHUNK";
export interface ChunkUploadedDTO {
    name: typeof UPLOAD_CHUNK,
}

export const INIT_UPLOAD = "INIT_UPLOAD";
export interface InitUploadDTO {
    name: typeof INIT_UPLOAD,
    data: {
        uuid: string,
    }
}