import React from 'react';
import './styles/Sidebar.css';

export default props => (
  <div id="sub-menu">
    <div>
      {props.children}
    </div>
  </div>
);
