import {authEpic} from "../auth/epics/AuthEpic";
import {notificationEpic} from "../notifications/Epics";
import {apiEpic} from "../api/epics";
import {organisationEpic} from "../data/organisations/epics";
import {batchEpic} from "../batchCall/epics";
import {machineEpic} from "../data/machines/epics";
import {ConfigDialogEpic} from "../pages/Machines/ConfigDialog/Epic";
import {AddDialogEpic} from "../pages/Files/AddDialog/Epic";
import {FilesEpic} from "../data/files/Epic";
import {AutoActivateEpic} from "../pages/Machines/AutoActivate/Epic";

export const epics = [
    ...authEpic,
    notificationEpic,
    apiEpic,
    organisationEpic,
    machineEpic,
    batchEpic,
    ConfigDialogEpic,
    AddDialogEpic,
    FilesEpic,
    AutoActivateEpic,
];