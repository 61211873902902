import React, {FC} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import {EmailField} from "../../form/components/EmailField";
import {PasswordField} from "../../form/components/PasswordField";
import {LoginFormValues, useLogin} from "../hooks/Login";
import {FormikProps} from "formik";

const LoginView: FC<FormikProps<LoginFormValues> & { processing: boolean }> = ({
                                                                                   touched,
                                                                                   errors,
                                                                                   handleSubmit,
                                                                                   getFieldProps,
                                                                                   processing,
                                                                               }) => {
    return (
        <Dialog open={true}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Login</DialogTitle>
                <DialogContent>
                    <EmailField label="email" {...getFieldProps('email')}
                                error={touched.email && errors.email !== undefined}
                                helperText={errors.email}
                    />

                    <PasswordField label="password" {...getFieldProps('password')}
                                   error={touched.password && errors.password !== undefined}
                                   helperText={errors.password}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        disabled={processing}
                        variant="contained"
                        color="primary"
                    >
                        Login
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export const Login: FC = () => {
    const {formik, processing} = useLogin();
    return <LoginView {...formik} processing={processing}/>;
};