import {AuthState} from "../AuthState";
import {AuthActions, AuthFailed, AuthLogin, AuthLogout, AuthNewSession, AuthSuccess} from "../actions";

const initState: AuthState = {
    isLoggingIn: false,
    validUser: JSON.parse(localStorage.getItem("authenticated") || "false"),
    session: localStorage.getItem("authSession") || "",
};

function loggingIn(oldState: AuthState, action: AuthLogin): AuthState {
    return Object.assign({}, oldState, {
        isLoggingIn: true,
        validUser: false,
    });
}

function tempSaveToStorage(action: AuthSuccess) {
    console.log("Setting local storage");
    localStorage.setItem("authPermissions", action.permissions);
    localStorage.setItem("authenticated", JSON.stringify(true));
    console.log("Done");
}

function loginSuccess(oldState: AuthState, action: AuthSuccess): AuthState {
    tempSaveToStorage(action);

    return Object.assign({}, oldState, {
        isLoggingIn: false,
        validUser: true,
    });
}

function loginFailed(oldState: AuthState, action: AuthFailed): AuthState {
    return Object.assign({}, oldState, {
        isLoggingIn: false,
        validUser: false,
    });
}

function newSession(oldState: AuthState, action: AuthNewSession): AuthState {
    localStorage.setItem("authSession", action.session);
    return Object.assign({}, oldState, {
        session: action.session
    });
}

function logout(oldState: AuthState, action: AuthLogout): AuthState {
    localStorage.clear();

    return Object.assign({}, oldState, {
        isLoggingIn: false,
        validUser: false,
        session: "",
    });
}

export function auth(state: AuthState = initState, action: AuthActions): AuthState {
    switch (action.type) {
        case "[auth] user tries to log in":
            return loggingIn(state, action);

        case "[auth] logged-out":
            return logout(state, action);

        case "[auth] user successfully logged in":
            return loginSuccess(state, action);

        case "[auth] user failed to logged in":
            return loginFailed(state, action);

        case "[auth] New session":
            return newSession(state, action);

        default:
            return state;
    }
}