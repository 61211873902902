import {ActionsObservable, ofType} from "redux-observable";
import {merge, pipe,} from "rxjs";
import {confirmError, ERROR_FIRED, ErrorActions, ErrorFired} from "../actions";
import {debounceTime, filter, map} from "rxjs/operators";
import {CouldNotAuthErrorNum, CredentialsIncorrectErrorNum} from "../NotificationsState";
import {userFailedLogin, userLogout} from "../../auth/actions";

const handleAutoHide = (action$: ActionsObservable<ErrorActions>) => action$.pipe(
    ofType(ERROR_FIRED),
    debounceTime(10 * 1000),
    pipe(
        map((action) => action as ErrorFired),
        map(() => {
            return confirmError();
        })
    )
);

const handleFailedLogout = (action$: ActionsObservable<ErrorActions>) => action$.pipe(
    ofType(ERROR_FIRED),
    map((action) => action as ErrorFired),
    filter(action => action.error.errorNumber === CredentialsIncorrectErrorNum),
    pipe(
        map((action) => {
            return userFailedLogin();
        })
    )
);

const handleLogout = (action$: ActionsObservable<ErrorActions>) => action$.pipe(
    ofType(ERROR_FIRED),
    map((action) => action as ErrorFired),
    filter(action => action.error.errorNumber === CouldNotAuthErrorNum),
    pipe(
        map((action) => {
            return userLogout("Not authenticated in backend");
        })
    )
);

export const notificationEpic = (action$: ActionsObservable<ErrorActions>) => merge(
    handleAutoHide(action$),
    handleLogout(action$),
    handleFailedLogout(action$),
);
