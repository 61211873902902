import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import {merge, pipe} from "rxjs";
import {API_REQUEST, API_RESPONSE, ApiActions, ApiRequestAction, ApiResponseAction} from "../actions";
import {concatMap, filter, map, mergeMap} from "rxjs/operators";
import {addAuthHeaders, convertFetchRequest, processResponseCode} from "../hooks";
import {onError} from "../../notifications/actions";
import {ServerConnectionErrorDescr, ServerConnectionErrorNum} from "../../notifications/NotificationsState";
import {newSessions, userSuccessfullyLoggedIn} from "../../auth/actions";
import {
    GET_MACHINES_V2, GET_TOKENS,
    LocationResponseDTO,
    LoginResponseDTO,
    MachineResponseDTO, MachineV2ResponseDTO,
    OrganisationResponseDTO, SET_MACHINE_TOKEN, TokenDTO
} from "../ApiResponses";
import {StoreState} from "../../StoreState";
import {
    addMachines,
    updateLocations,
    updateOrganisations
} from "../../data/organisations/actions";
import {addMachinesV2, addTokens, setEditableToken} from "../../data/machines/actions";
import {ConfigDialogReceiveData} from "../../pages/Machines/ConfigDialog/Actions";
import {GET_MACHINE_CONFIG, GetMachineConfigDTO} from "../../pages/Machines/ConfigDialog/Types";
import {GET_FILES, GetFilesDTO, REMOVE_FILES} from "../../data/files/Types";
import {ConfirmFilesRemoved, GetFiles, SetFiles} from "../../data/files/Actions";
import {INIT_UPLOAD, InitUploadDTO, UPLOAD_CHUNK} from "../../pages/Files/AddDialog/Types";
import {CloseAddDialog, UploadNextChunk} from "../../pages/Files/AddDialog/Actions";

const handleApiRequest = (action$: ActionsObservable<ApiActions>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(API_REQUEST),
    pipe(
        map((action) => action as ApiRequestAction),
        concatMap((async ({request}) => {
            try {
                request = addAuthHeaders(request, state$.value);
                const response = await fetch(request.url, convertFetchRequest(request));
                return await processResponseCode(request.name, request.responseVariables || [], response);
            } catch (error) {
                console.log(error);
                return onError({
                    errorNumber: ServerConnectionErrorNum,
                    description: ServerConnectionErrorDescr,
                })
            }
        }))
    )
);

const handleLoginResponse = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === "LOGIN"),
    pipe(
        concatMap((async (action) => {
            const response: LoginResponseDTO = action.response as LoginResponseDTO;
            return userSuccessfullyLoggedIn(response.data.permissions);
        }))
    )
);

const handleNewSession = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.newSession !== undefined),
    pipe(
        concatMap((async (action) => {
            return newSessions(action.newSession !== undefined ? action.newSession : "");
        }))
    )
);

const handleOrganisationResponse = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === "GET_ORGANISATIONS"),
    pipe(
        concatMap((async (action) => {
            const response: OrganisationResponseDTO = action.response as OrganisationResponseDTO;
            return updateOrganisations(response)
        }))
    )
);

const handleLocationResponse = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === "GET_LOCATIONS"),
    pipe(
        concatMap((async (action) => {
            const response: LocationResponseDTO = action.response as LocationResponseDTO;
            return updateLocations(response);
        }))
    )
);


const handleMachinesResponse = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === "GET_MACHINES"),
    pipe(
        concatMap((async (action) => {
            const response: MachineResponseDTO = action.response as MachineResponseDTO;
            return addMachines(response, action.variables[0]);
        }))
    )
);

const handleMachinesV2Response = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === GET_MACHINES_V2),
    pipe(
        concatMap((async (action) => {
            const response: MachineV2ResponseDTO = action.response as MachineV2ResponseDTO;
            return addMachinesV2(response);
        }))
    )
);

const handleMachineTokenResponse = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === GET_TOKENS),
    pipe(
        concatMap((async (action) => {
            const response: TokenDTO = action.response as TokenDTO;
            return addTokens(response);
        }))
    )
);

const handleMachineTokenSavedResponse = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === SET_MACHINE_TOKEN),
    pipe(
        concatMap((async (action) => {
            return setEditableToken(action.variables[0], true, true);
        }))
    )
);

const handleMachineConfigResponse = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === GET_MACHINE_CONFIG),
    pipe(
        map((action) => action.response as GetMachineConfigDTO),
        map((data) => ConfigDialogReceiveData(data)),
    )
);

const handleGetFilesResponse = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === GET_FILES),
    pipe(
        map(action => action.response as GetFilesDTO),
        map((data) => SetFiles(data)),
    )
);

const handleInitUploadFileResponse = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === INIT_UPLOAD),
    pipe(
        map(action => action.response as InitUploadDTO),
        map((data) => {
            return UploadNextChunk(data.data.uuid, 0);
        })
    )
);

const handleUploadChunkResponse = (action$: ActionsObservable<ApiActions>, state$: StateObservable<StoreState>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === UPLOAD_CHUNK),
    pipe(
        mergeMap((action) => {
            const nextChunk = state$.value.filesAdd.chunkPointer + 1;
            if (nextChunk < state$.value.filesAdd.chunks) {
                return [UploadNextChunk(action.variables[0], nextChunk)]
            }
            return [UploadNextChunk("", 0), CloseAddDialog(false), GetFiles()];
        })
    )
);

const handleFileRemovedResponse = (action$: ActionsObservable<ApiActions>) => action$.pipe(
    ofType(API_RESPONSE),
    map((action) => action as ApiResponseAction),
    filter(action => action.response.name === REMOVE_FILES),
    pipe(
        mergeMap(() => {
            return [ConfirmFilesRemoved(), GetFiles()];
        })
    )
);

export const apiEpic = (action$: ActionsObservable<ApiActions>, state$: StateObservable<StoreState>) => merge(
    handleApiRequest(action$, state$),
    handleNewSession(action$),
    handleLoginResponse(action$),
    handleOrganisationResponse(action$),
    handleLocationResponse(action$),
    handleMachinesResponse(action$),
    handleMachinesV2Response(action$),
    handleMachineTokenResponse(action$),
    handleMachineTokenSavedResponse(action$),
    handleMachineConfigResponse(action$),
    handleGetFilesResponse(action$),
    handleInitUploadFileResponse(action$),
    handleUploadChunkResponse(action$, state$),
    handleFileRemovedResponse(action$)
);