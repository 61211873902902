import {
    AUTO_ACTIVATE_CANCEL_ACTION_NAME,
    AUTO_ACTIVATE_OPEN_ACTION_NAME, AutoActivate_cancel_Types,
    AutoActivate_open_Types,
    AutoActivateActionTypes,
} from "./Types";
import {AutoActivateState} from "./State"

const initState: AutoActivateState = {
    open: false,
    token: "",
    key: "",
    redirect: false,
}

function open(state: AutoActivateState, action: AutoActivate_open_Types): AutoActivateState {
    return {
        ...state,
        open: true,
        redirect: false,
        token: action.token,
        key: action.key,
    }
}

function close(state: AutoActivateState, action: AutoActivate_cancel_Types): AutoActivateState {
    return {
        ...state,
        open: false,
        redirect: true,
    }
}

export function AutoActivateReducer(state = initState, action: AutoActivateActionTypes): AutoActivateState {
    switch (action.type) {
        case AUTO_ACTIVATE_OPEN_ACTION_NAME:
            return open(state, action);
        case AUTO_ACTIVATE_CANCEL_ACTION_NAME:
            return close(state, action);
        default:
            return state;
    }
}