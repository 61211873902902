import {StoreState} from "../../../StoreState";
import {View, ConfigDialogViewDispatches, ConfigDialogViewStates} from "./View";
import {connect} from "react-redux";
import {
    ConfigDialogClose,
    ConfigDialogCurrentPriceChange,
    ConfigDialogCurrentPriceSet, ConfigDialogEditAlias, ConfigDialogEditVideo,
    ConfigDialogMinPriceChange,
    ConfigDialogMinPriceSet, ConfigDialogSetOutOfService,
} from "./Actions";
import {getMachinesV2} from "../../../data/machines/actions";

const configDialogStates: (state: StoreState) => ConfigDialogViewStates = state => {
    return {
        open: state.configDialog.open,
        minPrice: state.configDialog.minPriceStr,
        currentPrice: state.configDialog.currentPriceStr,
        loading: state.configDialog.loading,
        machine: state.configDialog.machine,
        video: state.configDialog.machine?.video || null,
        outOfService: state.configDialog.outOfService,
    }
}

const configDialogDispatches: (dispatch: any) => ConfigDialogViewDispatches = dispatch => {
    return {
        onCurrentPriceChange: (value) => dispatch(ConfigDialogCurrentPriceChange(value)),
        onCurrentPrice: value => dispatch(ConfigDialogCurrentPriceSet(value)),
        onMinPriceChange: (value) => dispatch(ConfigDialogMinPriceChange(value)),
        onMinPrice: value => dispatch(ConfigDialogMinPriceSet(value)),
        onSave: () => {
            dispatch(ConfigDialogClose(true));
            dispatch(getMachinesV2());
        },
        onClose: () => dispatch(ConfigDialogClose(false)),
        onAlias: value => dispatch(ConfigDialogEditAlias(value)),
        onVideoChange: movie => dispatch(ConfigDialogEditVideo(movie)),
        onOutOfService: (event, checked) => {
            dispatch(ConfigDialogSetOutOfService(checked));
        },
    }
}

export const ConfigDialog = connect(
    configDialogStates,
    configDialogDispatches,
)(View);