
export const FILE_TYPE_NONE = ""
export const FILE_TYPE_VIDEO = "Video"
export const FILE_TYPE_UNSUPPORTED = "Unsupported"
export type FileType =
    typeof FILE_TYPE_NONE |
    typeof FILE_TYPE_VIDEO |
    typeof FILE_TYPE_UNSUPPORTED;

export interface AddDialogState {
    open: boolean,
    saving: boolean,
    name: string,
    nameError: string,
    file: File | undefined,
    fileType: FileType,
    fileError: string,
    chunkPointer: number,
    chunks: number,
    fileUuid: string,
}