import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Table, Paper, TableHead, TableRow, TableBody, TableCell, Input, InputAdornment, Fab, Select, MenuItem} from '@material-ui/core';
import {KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import AddIcon from '@material-ui/icons/Add';
import DateFnsUtils from '@date-io/date-fns';

const styles = theme => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  input: {
    width: "30%",
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
});

class Prices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prices: []
    };
  }

  addPrice() {
    this.setState({
      prices: this.state.prices.concat({
        day:1,
        from:new Date('2019-09-11T15:00'),
        to:new Date('2019-09-11T17:00'),
        price:1,
        coins:1,
      })
    });
  }

  setPriceProperty(index, property, value) {
    clearTimeout(this.state.prices[index].autoSave);
    let newPrices = this.state.prices;
    newPrices[index][property] = value;
    newPrices[index].autoSave = setTimeout(() => (console.log("autosave")), 3000);
    this.setState({
      prices: newPrices
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Day</TableCell>
            <TableCell align="right">From</TableCell>
            <TableCell align="right">To</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Coins</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.prices.map((row, index) => (
            <TableRow key={index}>
              {console.log(this.state)}
              <TableCell component="th" scope="row">
                <Select value={row.day} onChange={(event) => this.setPriceProperty(index, "day", event.target.value)}>
                  <MenuItem value={1}>Monday</MenuItem>
                  <MenuItem value={2}>Tuesday</MenuItem>
                  <MenuItem value={3}>Wednesday</MenuItem>
                  <MenuItem value={4}>Thursday</MenuItem>
                  <MenuItem value={5}>Friday</MenuItem>
                  <MenuItem value={6}>Saturday</MenuItem>
                  <MenuItem value={7}>Sunday</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="right">
                <KeyboardTimePicker
                  ampm={false}
                  value={row.from}
                  className={classes.input}
                  onChange={(date) => this.setPriceProperty(index, "from", date)}
                />
              </TableCell>
              <TableCell align="right">
                <KeyboardTimePicker
                  ampm={false}
                  value={row.to}
                  className={classes.input}
                  onChange={(date) => this.setPriceProperty(index, "to", date)}
                />
              </TableCell>
              <TableCell align="right">
                <Input
                  type="number"
                  value={row.price}
                  inputProps={{
                    min: 0,
                    max: 99,
                  }}
                  startAdornment={<InputAdornment position="start">€</InputAdornment>}
                  className={classes.input}
                  onChange={(event) => this.setPriceProperty(index, "price", event.target.value)}
                />
              </TableCell>
              <TableCell align="right">
                <Input
                  type="number"
                  value={row.coins}
                  inputProps={{
                    min: 1,
                    max: 42,
                  }}
                  className={classes.input}
                  onChange={(event) => this.setPriceProperty(index, "coins", event.target.value)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => this.addPrice()}>
        <AddIcon />
      </Fab>
    </Paper>
    </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(Prices);
