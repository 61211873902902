import {ConfigDialogState} from "./State";
import {
    CONFIG_DIALOG_DATA_ACTION_NAME,
    CONFIG_DIALOG_DATA_Types,
    CONFIG_DIALOG_EDIT_ALIAS_ACTION_NAME, CONFIG_DIALOG_EDIT_VIDEO_ACTION_NAME,
    CONFIG_DIALOG_ON_CLOSE_ACTION_NAME, CONFIG_DIALOG_ON_OUT_OF_SERVICE_ACTION_NAME,
    CONFIG_DIALOG_ON_PRICE_INPUT_ACTION_NAME,
    CONFIG_DIALOG_ON_PRICE_SET_ACTION_NAME,
    CONFIG_DIALOG_OPEN_ACTION_NAME,
    ConfigDialogActions,
    ConfigDialogEditAlias_Types, ConfigDialogEditVideo_Types, ConfigDialogOnOutOfService_Types,
    ConfigDialogOnPriceInput_Types,
    ConfigDialogOnPriceSet_Types,
    ConfigDialogOpen_Types,
    PriceTypes
} from "./Types";
import {ConfigDialogCurrentPriceSet, ConfigDialogMinPriceSet} from "./Actions";


const initState: ConfigDialogState = {
    open: false,
    loading: true,
    machine: null,
    currentPrice: 200,
    currentPriceStr: "2,00",
    minPrice: 100,
    minPriceStr: "1,00",
    outOfService: false,
}

function onPriceInput(state: ConfigDialogState, action: ConfigDialogOnPriceInput_Types): ConfigDialogState {
    switch (action.priceType) {
        case PriceTypes.current:
            return {
                ...state,
                currentPriceStr: action.value,
            }
        case PriceTypes.min:
            return {
                ...state,
                minPriceStr: action.value,
            }
    }
}

function onPriceSet(state: ConfigDialogState, action: ConfigDialogOnPriceSet_Types): ConfigDialogState {
    switch (action.priceType) {
        case PriceTypes.current:
            if (action.value < state.minPrice)
                return {
                    ...state,
                    currentPriceStr: (state.minPrice / 100).toFixed(2),
                    currentPrice: state.minPrice,
                }
            else
                return {
                    ...state,
                    currentPriceStr: (action.value / 100).toFixed(2),
                    currentPrice: action.value,
                }
        case PriceTypes.min:
            return {
                ...state,
                minPriceStr: (action.value / 100).toFixed(2),
                minPrice: action.value,
            }
    }
}

function onClose(state: ConfigDialogState): ConfigDialogState {
    return {
        ...state,
        open: false,
    }
}

function onOpen(state: ConfigDialogState, action: ConfigDialogOpen_Types): ConfigDialogState {
    return {
        ...state,
        open: true,
        loading: true,
        machine: action.machine,
    }
}

function onData(state: ConfigDialogState, action: CONFIG_DIALOG_DATA_Types): ConfigDialogState {
    const data = action.data.data;

    state = onPriceSet(state, ConfigDialogMinPriceSet(data.pricing.min));
    state = onPriceSet(state, ConfigDialogCurrentPriceSet(data.pricing.current));

    if (state.machine) {
        state = {
            ...state,
            outOfService: !state.machine.enabled,
            machine: {
                ...state.machine,
                video: action.data.data.video === "-" ? null : action.data.data.video,
            }
        }
    }

    return {
        ...state,
        loading: false,
    };
}

function onAlias(state: ConfigDialogState, action: ConfigDialogEditAlias_Types): ConfigDialogState {
    if (!state.machine) return state;

    return {
        ...state,
        machine: {
            ...state.machine,
            display_name: action.alias,
        }
    }
}

function onVideo(state: ConfigDialogState, action: ConfigDialogEditVideo_Types): ConfigDialogState {
    if (!state.machine) return state;

    return {
        ...state,
        machine: {
            ...state.machine,
            video: action.video ? action.video.uuid : null,
        }
    }
}

function onOutOfService(state: ConfigDialogState, action: ConfigDialogOnOutOfService_Types): ConfigDialogState {
    if (!state.machine) return state;

    return {
        ...state,
        outOfService: action.outOfService,
        machine: {
            ...state.machine,
        }
    }
}

export function configDialogReducer(state: ConfigDialogState = initState, action: ConfigDialogActions) {
    switch (action.type) {
        case CONFIG_DIALOG_ON_PRICE_INPUT_ACTION_NAME:
            return onPriceInput(state, action);
        case CONFIG_DIALOG_ON_PRICE_SET_ACTION_NAME:
            return onPriceSet(state, action);
        case CONFIG_DIALOG_ON_CLOSE_ACTION_NAME:
            return onClose(state);
        case CONFIG_DIALOG_OPEN_ACTION_NAME:
            return onOpen(state, action);
        case CONFIG_DIALOG_DATA_ACTION_NAME:
            return onData(state, action);
        case CONFIG_DIALOG_EDIT_ALIAS_ACTION_NAME:
            return onAlias(state, action);
        case CONFIG_DIALOG_EDIT_VIDEO_ACTION_NAME:
            return onVideo(state, action);
        case CONFIG_DIALOG_ON_OUT_OF_SERVICE_ACTION_NAME:
            return onOutOfService(state, action);
        default:
            return state;
    }
}