import {useFormik} from "formik";
import {userLoggedIn, userLogout} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from 'yup';
import {AuthState} from "../AuthState";
import {StoreState} from "../../StoreState";
import {useCallback} from "react";
import config from "../../config";


export const useAuthState = (): AuthState => {
    return useSelector<StoreState, AuthState>(({auth}) => {
        return auth;
    });
};


export const useValidUser = () => {
    return useAuthState().validUser;
};

export interface LoginFormValues {
    email: string;
    password: string;
}


export const useLoggingIn = () => {
    return useAuthState().isLoggingIn;
};

const loginValidationSchema = Yup.object({
    email: Yup.string()
        .email()
        .required(),

    password: Yup.string()
        .required()
});

export const useLogin = () => {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: config.dev ? 'test@kaplock.nl' : "",
            password: config.dev ? '123456test' : "",
        },
        validationSchema: loginValidationSchema,
        onSubmit: ({email, password}) => {
            dispatch(userLoggedIn(email, password));
        }
    });

    return {
        formik,
        processing: useLoggingIn(),
    };
};

export const useLogout = (why: string, onLogout?: () => void) => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(userLogout(why))
        ;
        if (onLogout) onLogout();
    }, [dispatch, why, onLogout]);
}